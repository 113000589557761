import { DateTime } from 'luxon';

import {
  SIMPLE_DATETIME_OPTS,
  adjustUTCtoTimezone,
  deriveDateFromDateTime,
  deriveDateTimeFromDate,
} from '…/app/common/dateUtils.mts';
import { PRODUCT_TYPES } from '…/app/deprecated/constants.mts';

import type { Engagement, EngagementSchedule } from '../../../Engagement.d.ts';


export function composeScheduleState({
  conversationDates,
  isEditMatchesEnabled,
  matchIntroAt,
  oneToOneEmails,
  optInClosesAt,
  optInOpensAt,
  timezoneIso,
}: EngagementSchedule) {
  const registration = {
    closes: optInClosesAt ? adjustUTCtoTimezone(optInClosesAt, timezoneIso) : null,
    opens: optInOpensAt ? adjustUTCtoTimezone(optInOpensAt, timezoneIso) : null,
  };
  const introAt = matchIntroAt ? adjustUTCtoTimezone(matchIntroAt, timezoneIso) : null;

  return {
    conversationDates: (
      conversationDates?.map((date) => adjustUTCtoTimezone(date, timezoneIso))
      ?? new Array<DateTime | undefined>()
    ),
    isEditMatchesEnabled,
    matchEditingCloses: introAt
      ?.minus({ hours: 24 })
      .set({
        hour: 23,
        minute: 45, // 59 - buffer
        second: 59,
      })
      ?? null,
    matchIntroAt: introAt,
    matchesGenerated: registration.closes?.plus({ hours: 24 }) ?? null,
    // ORB-334
    oneToOneEmails: {
      chaser: {
        id: oneToOneEmails?.chaser.id,
        isDisabled: oneToOneEmails?.chaser.isDisabled,
        sendAt: getEmailDT(oneToOneEmails, 'chaser', timezoneIso),
        type: oneToOneEmails?.chaser.type,
      },
      invite: {
        id: oneToOneEmails?.invite.id,
        isDisabled: oneToOneEmails?.invite.isDisabled,
        sendAt: getEmailDT(oneToOneEmails, 'invite', timezoneIso),
        type: oneToOneEmails?.invite.type,
      },
    },
    optInClosesAt: registration.closes,
    optInOpensAt: registration.opens,
    timezoneIso,
  };
}
export type ScheduleState = ReturnType<typeof composeScheduleState>;

// ORB-334
function getEmailDT(
  emails: EngagementSchedule['oneToOneEmails'],
  prop: keyof NonNullable<EngagementSchedule['oneToOneEmails']>,
  tz: EngagementSchedule['timezoneIso'],
) {
  const email = emails?.[prop];

  if (email?.sendAt) return adjustUTCtoTimezone(email.sendAt, tz);

  return null;
}

export const isConvoDateDT = (product: Engagement['product']) => product !== PRODUCT_TYPES.ONE_TO_ONE;
export const composeConvoDateProps = (product: Engagement['product']): {
  deriver(dtISO: DateTime | ISO_8601): ISO_8601 | null,
  type: 'datetime-local' | 'date',
} => isConvoDateDT(product)
  ? {
    deriver: deriveDateTimeFromDate,
    type: 'datetime-local',
  }
  : {
    deriver: deriveDateFromDateTime,
    type: 'date',
  };

export function composeFormValues(
  {
    conversationDates,
    isEditMatchesEnabled,
    matchIntroAt,
    matchesGenerated,
    oneToOneEmails: {
      chaser,
      invite,
    },
    optInClosesAt,
    optInOpensAt,
    timezoneIso,
  }: ScheduleState,
  product: Engagement['product'],
) {
  const { deriver } = composeConvoDateProps(product);

  return {
    conversationDates: conversationDates[0]
      ? conversationDates.map((convoDate) => convoDate && deriver(convoDate))
      : conversationDates,
    isEditMatchesEnabled: !!isEditMatchesEnabled,
    matchIntroAt: matchIntroAt?.toISODate() ?? '',
    matchesGenerated: matchesGenerated?.toISODate() ?? '',
    // ORB-334
    oneToOneEmails: {
      chaser: {
        id: chaser.id,
        isDisabled: chaser.isDisabled,
        sendAt: chaser.sendAt?.toISO(SIMPLE_DATETIME_OPTS) ?? '',
        type: chaser.type,
      },
      invite: {
        id: invite.id,
        isDisabled: invite.isDisabled,
        sendAt: invite.sendAt?.toISO(SIMPLE_DATETIME_OPTS) ?? '',
        type: invite.type,
      },
    },
    optInClosesAt: optInClosesAt
      ? optInClosesAt.toISODate()
      : '',
    optInOpensAt: optInOpensAt
      ? optInOpensAt.toISODate()
      : '',
    timezoneIso: timezoneIso ?? '',
  };
}
