import { type FC, useState } from 'react';
import clsx from 'clsx';

import { IIcon } from '…/app/w/workspace/common/Icon/Icon.tsx';
import { copyToClipboard } from '…/app/common/copy-utils.mts';

import classes from './DNSValidationCard.module.css';

export interface DNSRecord {
  data: string;
  host: string;
  type: string;
  valid: boolean;
}

interface DNSRecordRowProps {
  copied: string | null;
  fieldType: 'host' | 'data';
  label: string;
  onCopy: (text: string, field: string) => void;
  recordKey: string;
  rowColor: string;
  value: string;
}

const DNSRecordRow: FC<DNSRecordRowProps> = ({
  copied,
  fieldType,
  label,
  onCopy,
  recordKey,
  rowColor,
  value,
}) => (
  <div className={classes.Row}>
    <span className={classes.Label}>{label}</span>

    <div className={classes.ValueContainer}>
      <code className={clsx(classes.CodeBlock, rowColor)}>
        {value}
      </code>

      <button
        aria-label={`Copy ${fieldType}`}
        className={classes.CopyButton}
        onClick={() => onCopy(value, `${recordKey.toUpperCase()} ${fieldType === 'host' ? 'HOST' : 'VALUE'}`)}
      >
        {copied === `${recordKey}-${fieldType}` ? (
          <IIcon label="Check" name="check" />
        ) : (
          <IIcon label="Copy" name="copy" />
        )}
      </button>
    </div>
  </div>
);

DNSRecordRow.displayName = 'DNSRecordRow';

interface DNSValidationCardProps {
  recordKey: string;
  record: DNSRecord;
}

export const DNSValidationCard: FC<DNSValidationCardProps> = ({ recordKey, record }) => {
  const [copied, setCopied] = useState<string | null>(null);

  const _copyToClipboard = (text: string, field: string) => {
    copyToClipboard(text, field);
    setCopied(field);
    setTimeout(() => setCopied(null), 2000);
  };

  const rowColor = record.valid ? 'bg-green-100 border-green-200' : 'bg-amber-100 border-amber-200';

  return (
    <div className="p-4 rounded-lg border border-width-1 border-gray-50">
      {/* <h2 className={classes.Title}>{recordKey}</h2> */}

      <div className={classes.Grid}>
        <div className={classes.Row}>
          <span className={classes.Label}>Type:</span>

          <div className={classes.ValueContainer}>
            {record.type.toUpperCase()}
          </div>
        </div>

        <DNSRecordRow
          copied={copied}
          fieldType="host"
          label="Host:"
          onCopy={_copyToClipboard}
          recordKey={recordKey}
          rowColor={rowColor}
          value={record.host}
        />

        <DNSRecordRow
          copied={copied}
          fieldType="data"
          label="Value:"
          onCopy={_copyToClipboard}
          recordKey={recordKey}
          rowColor={rowColor}
          value={record.data}
        />
      </div>
    </div>
  );
};

DNSValidationCard.displayName = 'DNSValidationCard';
