import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'sonner';
import Button from 'form5/react/Button';
import Form from 'form5/react/Form';
import Field from 'form5/react/Field';

import { SubmitButton } from '…/app/common/SubmitButton/SubmitButton.jsx';
import { Dialog } from '…/app/w/workspace/common/Dialog/Dialog.tsx';

import { addWhitelabelingDomain } from './addWhitelabelingDomain.op.mts';

interface AddDomainDialogProps {
  open: boolean;
  setRefresh: (refresh: boolean) => void;
  setOpen: (open: boolean) => void;
}

export const AddDomainDialog = ({
  open,
  setRefresh,
  setOpen,
}: AddDomainDialogProps) => {
  const { workspaceId } = useParams();

  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [errors, setErrors] = useState<string | null>(null);

  const handleSubmit = async (data: any) => {
    setIsSaving(true);

    return addWhitelabelingDomain({
      rootDomain: data.rootDomain,
      workspaceId,
    })
      .then(() => {
        toast.success(`Saved ${data.rootDomain} for email whitelabeling`);
      })
      .catch((error) => {
        console.error('Error adding domain:', error);
      })
      .finally(() => {
        setIsSaving(false);
        setRefresh(true);
        setOpen(false);
      });
  };

  const onClose = () => {
    setIsSaving(false);
    setErrors(null);
    setOpen(false);
  };

  return (
    <Dialog
      backdropped
      open={!!open}
      setOpen={onClose}
    >
      <Form name="add-domain" onSubmit={handleSubmit}>
        <h2>Add Domain for Whitelabeling</h2>

        <Field
          label="Root Domain"
          name="rootDomain"
          placeholder="example.com"
          required
          type="text"
        />

        {errors && (
          <div className="Error">
            {errors}
          </div>
        )}

        <SubmitButton
          appearance={Button.APPEARANCES.AFFIRMING}
          disabled={isSaving}
          isSubmitting={isSaving}
          type="submit"
        >
          Add Domain
        </SubmitButton>
      </Form>
    </Dialog>
  );
};

AddDomainDialog.displayName = 'AddDomainDialog';
