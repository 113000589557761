import { rpc } from '…/app/common/rpc/client.mts';


const AVAILABLE_CSV_DOWNLOAD_ENDPOINTS = new Set(['opt-in-forms', 'matches', 'feedback-forms', 'nochat-forms']);

/**
 * @param {String} inputString
 * @param {Boolean} withTimestamp
 *
 * @return {String}
 */
export const createDownloadFilename = (inputString, withTimestamp = false) => {
  let filename = inputString.trim();

  // Replace any & with `and`
  filename = filename.replace(/[&]/g, 'and');

  // Replace any special characters
  filename = filename.replace(/[\\#,/+()$~%.!'":*?<>{}]/g, '');

  // Replace any spaces to dashes
  filename = filename.replace(/[ ]/g, '-').toLowerCase();

  if (withTimestamp) {
    const timestamp = new Date().getTime();
    filename = `${filename}-${timestamp}`;
  }

  return filename;
};

export const downloadCSV = (endpoint, searchParams, filename) => rpc.get(endpoint, { searchParams })
  .blob()
  .then((blob) => {
    const file = new File([blob], filename, { type: 'text/csv' });
    const url = URL.createObjectURL(file);
    window.open(url);
    URL.revokeObjectURL(url);
  });

/**
 * @param {String} workspaceId
 * @param {Audience} audience
 */
export const downloadAudience = (workspaceId, audience) => {
  const filename = createDownloadFilename(audience.name, true);

  return downloadCSV(
    `downloads/${!audience.isPrimary ? 'audience-' : ''}members`,
    {
      ...(!audience.isPrimary && { audienceId: audience.id }),
      filename,
      workspaceId,
    },
    filename,
  );
};

/**
 * @param {String} workspaceId
 * @param {Engagement} engagement
 */
export const downloadEngagementSection = (workspaceId, engagement, section, filterQueryParams = {}) => {
  // section can be any of these
  if (!AVAILABLE_CSV_DOWNLOAD_ENDPOINTS.has(section)) throw new Error(`Can't download engagement section ${section}`);

  const filename = createDownloadFilename(`engagement-${engagement.id}-${section}`, true);

  // NOTE: As we roll this out we'll limit the section keys.
  if (section === 'opt-in-forms') {
    return downloadCSV(
      `v1/engagements/${engagement.id}/${section}`,
      {
        ...filterQueryParams,
        download: filename,
      },
      filename,
    );
  }

  return downloadCSV(
    `downloads/engagement-${section}`,
    {
      engagementId: engagement.id,
      filename,
      workspaceId,
    },
    filename,
  );
};
