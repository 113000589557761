export const UPDATE_EMAIL = `
mutation UpdateEmail(
  $emailId: ID!
  $email: UpdateEmailInput!
) {
  email: updateEmail(
    emailId: $emailId
    email: $email
  ) {
    id
    bodyJson
    callToAction
    isDisabled
    preheader
    sendAt
    subject
    type
  }
}
`;
