import type { GraphQLFilter } from '…/app/common/filtering/filters.d.ts';
import { getGQLFilterCount } from '…/app/common/refinement/getFilterCount.mts';
import { gqlOperation } from '…/app/common/rpc/gqlOperation.mts';
import type { PaginatedRecords, PaginationProps } from '…/app/common/pagination/pagination.d.ts';

import type { Sort } from '…/app/common/Table/Table.tsx';

import type {
  Engagement,
} from './Engagement.d.ts';

import GET_ENGAGEMENTS from './getEngagements.gql';


export function getEngagements(
  workspaceId: ObjectId,
  {
    filters,
    sort,
    search,
    ...rest
  }: PaginationProps & {
    filters?: GraphQLFilter[],
    sort?: Sort<SortableFields>
  } = {},
) {
  const numFilters = getGQLFilterCount(filters);

  return gqlOperation<{
    workspace: {
      engagements: PaginatedRecords<Engagement>,
    },
  }>(
    GET_ENGAGEMENTS,
    {
      search,
      ...rest,
      ...(filters?.length && {
        filters,
      }),
      ...(sort?.fieldName && {
        sort: {
          direction: sort.ascending ? 'ASC' : 'DESC',
          field: mapSortableFieldsToGQLConstant[sort.fieldName],
        },
      }),
      workspaceId,
    },
    {
      ...((search || numFilters) && {
        eventData: {
          data: JSON.stringify({
            filters,
            numFilters,
            search,
            workspaceId,
          }),
          name: 'Engagements Search & Filter',
        },
      }),
    },
  )
    .then((data) => data.workspace);
}

const mapSortableFieldsToGQLConstant = {
  'audience.name': 'AUDIENCE_NAME',
  // 'engagementStats.avgMatchRating': 'MATCH_RATING',
  // 'engagementStats.nps': 'NPS',
  // 'engagementStats.numFeedbackForms': 'NUM_FEEDBACK_FORMS',
  'matchesCount': 'MATCH_COUNT',
  'name': 'NAME',
  'optInClosesAt': 'OPT_IN_CLOSES_AT',
  'optInFormsCount': 'OPT_IN_FORM_COUNT',
  'optInOpensAt': 'OPT_IN_OPENS_AT',
  'product': 'PRODUCT',
  'status': 'STATUS',
};

export type SortableFields = keyof typeof mapSortableFieldsToGQLConstant;
