.Content {
  padding: 0.75rem 1.5rem;
}

.Header {
  background: white;
  border-bottom: 1px solid var(--border-color);
  box-shadow: var(--box-shadow);
  padding: 1.5em;
}

.ContentScrollable {
  /* max-height: 69vh; */
  overflow-y: scroll;
  padding-bottom: 4rem;
}

.ContentScrollable::after {
  background: linear-gradient(to bottom, rgb(255 255 255 / 0%) 0%, rgb(255 255 255 / 100%) 100%);
  bottom: 0;
  content: "";
  height: 2rem;
  left: 0;
  position: absolute;
  right: 0;
}
