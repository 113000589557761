import type { Engagement, EngagementSchedule } from '../../Engagement.d.ts';

export function engagementEmailsMigration(engagement: Engagement | EngagementSchedule): any {
  const chaser = engagement?.emails?.find((email) => email.type === 'CHASER');
  const invite = engagement?.emails?.find((email) => email.type === 'INVITE');
  // NOTE: ORB-334
  const oneToOneEmails = engagement?.emails?.length
    ? {
      chaser,
      invite,
    }
    : null;
  return {
    ...engagement,
    oneToOneEmails,
  };
}
