import { FLAGS } from '…/app/common/permissions/Features/FLAGS.mts';

type MeterData = {
  high?: number;
  low?: number;
  min?: number;
  max?: number;
}

type CardData = {
  decimals?: number;
  flag: string;
  infoBlurb?: string;
  isPercent?: boolean;
  meter?: MeterData;
  orbiit?: number;
  title: string;
  value?: number;
}

type OptionData = {
  id: string;
  count: number;
  percentage: number;
  text: string;
}

type OptionCardData = CardData & {
  questionType: string;
  id: string;
  values: OptionData[];
}

// TODO: Optimize query to not request the data when feature flags are not present
// but still show the cards with the feature flag to indicate what users are missing
// out on.
const questionMetrics = {
  companySizeQuestionCountByType: new Array(),
  companyStageQuestionCountByType: new Array(),
  decisionQuestionCountByType: new Array(),
  experienceQuestionCountByType: new Array(),
  nochatReasonCountByType: new Array(),
  reconnectQuestionCountByType: new Array(),
};

const advancedMetrics = {
  superUsersBreakdown: new Array(),
  // **NOTE** Investigate bug in Insights API and Frontend rendering
  // topCompanyCountByType: [],
  // topRoleCountByType: [],
};
type AdvancedMetrics = typeof advancedMetrics;

const coreMetrics = {
  engagementCount: 0,
  npsScore: 0,
  optinCount: 0,
  optinRate: 0,
};

export const baseMetrics = {
  feedbackCount: 0,
  feedbackRate: 0,
  matchCount: 0,
  matchRatingAvg: 0,
  nochatCount: 0,
  optinOneTimeCount: 0,
  optinRepeatsCount: 0,
  optinUniqueCount: 0,
  ...coreMetrics,
  ...questionMetrics,
  ...advancedMetrics,
};

export const formatOptionPercentages = (data: any[], idPrefix: string): OptionData[] => {
  if (!data?.length) return new Array();

  const total = data.reduce((acc, o) => acc + o.count, 0);

  return data.map((o, ix) => ({
    count: o.count,
    id: `${idPrefix}-${ix}`,
    percentage: 100.0 * (o.count / total),
    text: o.answer,
  }));
};

export const formatAdvancedAnalyticsData = (data: any[], idPrefix: string): OptionData[] => {
  if (!data?.length) return new Array();

  const max = data.reduce((acc, o) => Math.max(acc, o.count ?? o.optinsInTimerange), 0);

  return data.map((o, ix) => ({
    count: o.count || o.optinsInTimerange,
    id: `${idPrefix}-${ix}`,
    percentage: 100.0 * ((o.count || o.optinsInTimerange) / max),
    text: o.text,
  })).sort((a, b) => b.count - a.count)
    .slice(0, 10);
};

export const formatOverviewCards = (metrics: any): CardData[] => [
  {
    flag: FLAGS.analyticsMetricsCore,
    infoBlurb: 'The number of rounds in the specified period.',
    title: 'Rounds',
    value: metrics.engagementCount,
  },
  {
    flag: FLAGS.analyticsMetricsCore,
    infoBlurb: 'The total number of participants joining the round in the specified period.',
    title: 'Opt-ins',
    value: metrics.optinCount,
  },
  {
    flag: FLAGS.analyticsMetricsCore,
    isPercent: true,
    title: 'Opt-in Rate',
    value: metrics.optinRate,
  },
  {
    flag: FLAGS.analyticsMetricsCore,
    infoBlurb: 'The average Net Promoter Score across all rounds in the specified period.',
    // meter: {
    //   high: 90,
    //   low: 78,
    //   max: 100,
    //   min: -100,
    // },
    // orbiit: 84,
    title: 'NPS Score',
    value: metrics.npsScore,
  },
];

export const formatEngagementCards = (metrics: any): any[] => [
  {
    flag: FLAGS.analyticsMetricsPremium,
    infoBlurb: 'The total new number of participants joining the round in the specified period.',
    title: 'New Opt-ins',
    value: metrics.optinOneTimeCount,
  },
  {
    flag: FLAGS.analyticsMetricsPremium,
    infoBlurb: 'The total number of participants joining the round more than once in the specified period.',
    title: 'Repeat Opt-ins',
    value: metrics.optinRepeatsCount,
  },
  {
    flag: FLAGS.analyticsMetricsPremium,
    infoBlurb: 'The total number of participants joining the round at all in the specified period.',
    title: 'Unique Members',
    value: metrics.optinUniqueCount,
  },
  {
    flag: FLAGS.analyticsMetricsBase,
    infoBlurb: 'The total number of matches across all rounds in the specified period.',
    title: 'Matches',
    value: metrics.matchCount,
  },
  {
    flag: FLAGS.analyticsMetricsPremium,
    infoBlurb: 'The total number of unique companies in the specified period.',
    title: 'Companies',
    value: metrics.uniqueCompanyCount,
  },
  {
    flag: FLAGS.analyticsMetricsBase,
    infoBlurb: 'The total number of participants who did not end up meeting.',
    title: 'No-chats',
    value: metrics.nochatCount,
  },
  {
    flag: FLAGS.analyticsMetricsBase,
    infoBlurb: 'The number of participants who indicated they have rescheduled their conversation.',
    title: 'Rescheduled',
    value: metrics.rescheduledCount,
  },
  {
    flag: FLAGS.analyticsMetricsBase,
    infoBlurb: 'The total number of participants who opted in but did actually not attend.',
    title: 'No-shows',
    value: metrics.noshowCount,
  },
  {
    flag: FLAGS.analyticsMetricsBase,
    infoBlurb: 'The total number of feedback forms submitted by participants across all rounds in the specified period.',
    title: 'Feedback Sent',
    value: metrics.feedbackCount,
  },
  {
    decimals: 1,
    flag: FLAGS.analyticsMetricsBase,
    // meter: {
    //   high: 4.4,
    //   low: 4,
    //   max: 5,
    //   min: 0,
    // },
    // orbiit: 4.24,
    title: 'Match Rating',
    value: metrics.matchRatingAvg,
  },
  {
    flag: FLAGS.analyticsMetricsBase,
    infoBlurb: '',
    isPercent: true,
    // meter: {
    //   high: 23,
    //   low: 22,
    //   max: 100,
    //   min: 0,
    // },
    // orbiit: 0.229,
    title: 'Feedback Rate',
    value: metrics.feedbackRate,
  },
  {
    flag: FLAGS.analyticsMetricsBase,
    infoBlurb: 'The total number of matches that we reconnected over the period.',
    title: 'Matches Reconnected',
    value: metrics.matchReconnectCount,
  },
];

export const formatOptInQuestionCards = (metrics: any): OptionCardData[] => [
  {
    flag: FLAGS.analyticsMetricsQuestions,
    id: 'years-of-experience',
    infoBlurb: 'Breakdown of all the answers for the years of experience question.',
    questionType: 'SingleSelectStep',
    title: 'Years of Experience',
    values: formatOptionPercentages(metrics.experienceQuestionCountByType, 'years-of-experience'),
  },
  {
    flag: FLAGS.analyticsMetricsQuestions,
    id: 'company-stage',
    infoBlurb: 'Breakdown of all the answers for the company stage question.',
    questionType: 'SingleSelectStep',
    title: 'Company Stage',
    values: formatOptionPercentages(metrics.companyStageQuestionCountByType, 'company-stage'),
  },
  {
    flag: FLAGS.analyticsMetricsQuestions,
    id: 'company-size',
    infoBlurb: 'Breakdown of all the answers for the company size question.',
    questionType: 'SingleSelectStep',
    title: 'Company Size',
    values: formatOptionPercentages(metrics.companySizeQuestionCountByType, 'company-size'),
  },
];

export const formatFeedbackQuestionCards = (metrics: any): OptionCardData[] => [
  {
    flag: FLAGS.analyticsMetricsQuestions,
    id: 'member-reconnect',
    infoBlurb: 'Breakdown of all the answers for the member reconnect feedback question.',
    questionType: 'SingleSelectStep',
    title: 'Reconnect Match Members',
    values: formatOptionPercentages(metrics.reconnectQuestionCountByType, 'member-reconnect'),
  },
  {
    flag: FLAGS.analyticsMetricsQuestions,
    id: 'better-decision',
    infoBlurb: 'Breakdown of all the answers for the feedback question if a better decision was made as a result of the conversation.',
    questionType: 'SingleSelectStep',
    title: 'Better Decision after the Match',
    values: formatOptionPercentages(metrics.decisionQuestionCountByType, 'better-decision'),
  },
  {
    flag: FLAGS.analyticsMetricsQuestions,
    id: 'nochat-reason',
    infoBlurb: 'Breakdown of all the answers for the no-chat reason feedback question.',
    questionType: 'SingleSelectStep',
    title: 'Reason for reporting a No-Chat',
    values: formatOptionPercentages(metrics.nochatReasonCountByType, 'nochat-reason'),
  },
];

export const formatAdvancedMetricsCards = (metrics: AdvancedMetrics): OptionCardData[] => [
  {
    flag: FLAGS.analyticsMetricsAdvanced,
    id: 'power-users',
    infoBlurb: 'Breakdown of your power end-users who have opted in the most times.',
    questionType: 'Custom',
    title: 'Power users (top 10)',
    values: formatAdvancedAnalyticsData(metrics.superUsersBreakdown.map((o) => ({
      ...o,
      text: `${o.firstName} ${o.lastName}`,
    })), 'power-users'),
  },
  // {
  //   flag: FLAGS.analyticsMetricsAdvanced,
  //   id: 'top-companies',
  //   infoBlurb: 'Breakdown of your top companies who have opted in the most times.',
  //   questionType: 'Custom',
  //   title: 'Top Companies',
  //   values: formatAdvancedAnalyticsData(metrics.topCompanyCountByType.map((o) => ({
  //     ...o,
  //     text: o.company,
  //   })), 'top-companies'),
  // },
  // {
  //   flag: FLAGS.analyticsMetricsAdvanced,
  //   id: 'top-roles',
  //   infoBlurb: 'Breakdown of your top roles who have opted in the most times.',
  //   questionType: 'Custom',
  //   title: 'Top Roles',
  //   values: formatAdvancedAnalyticsData(metrics.topRoleCountByType.map((o) => ({
  //     ...o,
  //     text: o.role,
  //   })), 'top-roles'),
  // },
];
