import { OrbiitIcon } from '…/app/deprecated/ODS/OrbiitIcon/index.jsx';
import { formatDate } from '…/app/common/dateUtils.mts';

import { IIcon, SocialIconLink } from '…/app/w/workspace/common/Icon/Icon.tsx';
import type { TableDatum } from '…/app/common/Table/Table.tsx';
import { Popover } from '…/app/w/workspace/common/Popover/Popover.tsx';
import { CTA } from '…/app/w/workspace/common/CTA/CTA.tsx';
import type { DeSelectAllState } from '…/app/w/workspace/common/DeSelectAll/DeSelectAll.tsx';
import { SelectCheckbox } from '…/app/w/workspace/common/DeSelectAll/DeSelectAll.tsx';

import type {
  Member,
  MembersDict,
} from './members/Member.d.ts';
import type { upsertMembers } from './members/upsertMembers.op.mts';

import type {
  Audience,
  AudienceId,
} from './Audience.d.ts';
import type { WidgetConfig } from './widgetDict.mts';
import type { extractCustomFields } from './extractCustomFields.mts';

import classes from './AudiencePage.module.css';
import type { FieldProps } from 'form5/react/Field';

export function transformMembersToTabularData(
  members: MembersDict,
  {
    page,
    limit,
  }: {
    page: Int,
    limit: Int,
  },
  {
    audiences,
    customFieldLabelsMap,
    customFieldValueMap,
    onSelect,
    onUpsertMember,
    openWidget,
    selected,
  }: {
    audiences: Map<AudienceId, Audience>,
    customFieldLabelsMap: ReturnType<typeof extractCustomFields>['customFieldLabels'],
    customFieldValueMap: ReturnType<typeof extractCustomFields>['customFieldValues'],
    onSelect: FieldProps['onChange'],
    onUpsertMember<A extends Parameters<typeof upsertMembers>>(
      update: A[1],
      audienceIds: A[2],
    ): Promise<void>,
    openWidget: (config: WidgetConfig) => void,
    selected: DeSelectAllState,
  },
) {
  const start = (page - 1) * limit;
  const end = page * limit;

  return Array.from(members.values())
    .slice(start, end)
    .map((member) => {
      const {
        id,
        email,
        fields,
        flagged,
      } = member;
      const {
        company,
        fullName,
        role,
        url,
      } = fields || {};

      const standardFields: TableDatum[] = [
        {
          display: (
            <SelectCheckbox
              ariaLabel={`select ${fullName || email}`}
              checked={selected.get(id)}
              id={id}
              name="member"
              onChange={onSelect}
            />
          ),
          fieldName: 'checkbox',
          raw: id,
        },
        {
          display: (
            <div className={classes.FlexRow}>
              <CTA
                guise={CTA.GUISES.LINK}
                to={() => openWidget({
                  data: {
                    member,
                  },
                  key: `history.${member.id}`,
                  name: 'history',
                  type: 'drawer',
                })}
              >
                {fullName || email}
              </CTA>

              {url && (<SocialIconLink url={url} />)}
            </div>
          ),
          fieldName: 'name',
          raw: fullName,
        },
        {
          display: (
            <div className={classes.Controls}>
              <CTA
                guise={CTA.GUISES.LINK}
                menuitem=""
                to={() => openWidget({
                  data: {
                    audiences,
                    member,
                    onSubmit: onUpsertMember,
                  },
                  key: `edit.${member.id}`,
                  name: 'edit',
                  type: 'dialog',
                })}
              >
                <IIcon name="settings" />
              </CTA>

              {getEmailWarning(flagged ?? {}, email)}
            </div>
          ),
          fieldName: 'meta',
        },
        {
          fieldName: 'role',
          raw: role,
        },
        {
          display: member.remoteId
            ? (
              <IIcon label="Imported from hivebrite" name="hivebrite" />
            )
            : null,
          fieldName: 'remoteId',
        },
        {
          fieldName: 'company',
          raw: company,
        },
      ];

      const customFields: TableDatum[] = Array.from(customFieldLabelsMap.keys()).map(
        (customFieldId) => {
          const memberCustomFieldEntry = member.customFields?.find(
            (memberCustomField) => memberCustomField.id === customFieldId,
          );
          const vals = memberCustomFieldEntry ? memberCustomFieldEntry.selectedOptions.map((optId) => customFieldValueMap.get(optId)) : [''];
          return {
            fieldName: customFieldId,
            raw: vals.join(', '),
          };
        },
      ) ?? new Array();

      return Array.prototype.concat(standardFields, customFields);
    });
}

function getEmailWarning(
  {
    isBounced,
    isUnsubscribed,
    unsubscribedUntil,
  }: Partial<NonNullable<Member['flagged']>>,
  email: Member['email'],
) {
  if (isBounced) {
    return (
      <Popover
        anchor={(props) => (
          <OrbiitIcon
            {...props}
            icon="EmailBouncedIcon"
            size="large"
          />
        )}
        minimal
        type={Popover.TYPES.TOOLTIP}
      >
        {email} is bouncing
      </Popover>
    );
  }

  if (isUnsubscribed) {
    return (
      <Popover
        anchor={(props) => (
          <OrbiitIcon
            {...props}
            icon="SnoozedIcon"
            size="large"
          />
        )}
        minimal
        type={Popover.TYPES.TOOLTIP}
      >
        Member unsubscribed
      </Popover>
    );
  }

  if (unsubscribedUntil) {
    return (
      <Popover
        anchor={(props) => (
          <OrbiitIcon
            {...props}
            icon="SnoozedIcon"
            size="large"
          />
        )}
        minimal
        type={Popover.TYPES.TOOLTIP}
      >
        Member unsubscribed until {formatDate(unsubscribedUntil)}
      </Popover>
    );
  }

  return null;
}
