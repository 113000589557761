import Button from 'form5/react/Button';

// FIXME:
import { OrbiitIcon } from '…/app/deprecated/ODS/OrbiitIcon/index.jsx';

import { IIcon } from '…/app/w/workspace/common/Icon/Icon.tsx';

import classes from './FilterIcon.module.css';

export function FilterIcon({
  count,
  onClick,
  testId,
} : {
  count?: number,
  onClick: () => void,
  testId: string,
}) {
  return (
    <Button
      className={classes.FilterIconButton}
      // @ts-ignore
      count={count}
      data-testid={testId}
      onClick={onClick}
      title="Filters"
      variant="glyph"
    >
      {/* <IIcon name="funnel" /> */}
      <OrbiitIcon
        className={classes.FilterIcon}
        count={count}
        icon="FilterIcon"
        inline
      />
    </Button>
  );
}

FilterIcon.displayName = 'FilterIcon';
