._DNSValidationCard_1sicy_1 {
  border: 1px solid var(--border-color, #e5e7eb);
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  padding: 1rem;
}

/* .Title {
  font-weight: 600;
  margin-bottom: 0.75rem;
} */

._Grid_1sicy_15 {
  display: grid;
  gap: 0.5rem;
  margin-top: 0.75rem;
}

._Row_1sicy_21 {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

@media (640px <= width <= 2400px) {
  ._Row_1sicy_21 {
    align-items: center;
    flex-direction: row;
  }
}

._Label_1sicy_34 {
  font-size: 0.875rem;
  font-weight: 500;
  width: 4rem;
}

._ValueContainer_1sicy_40 {
  align-items: center;
  display: flex;
  flex: 1;
  gap: 0.5rem;
}

._CodeBlock_1sicy_47 {
  border-radius: 0.25rem;
  display: block;
  flex: 1;
  font-size: 0.875rem;
  overflow-x: auto;
  padding: 0.5rem 1rem;
}

._CopyButton_1sicy_56 {
  background: transparent;
  border: none;
  border-radius: 0.25rem;
  cursor: pointer;
  padding: 0.5rem;
}

._CopyButton_1sicy_56:hover {
  background-color: #f3f4f6;
}
