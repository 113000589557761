import debounce from 'debounce-promise';
import Select from 'react-select';
import type { GroupBase, OptionsOrGroups } from 'react-select';
import AsyncSelect from 'react-select/async';

interface ComboboxProps<T> {
  defaultOptions?: OptionsOrGroups<T, GroupBase<T>>;
  isLoading?: boolean;
  label?: string;
  name: string;
  onChange: (values: T) => void;
  options?: OptionsOrGroups<T, GroupBase<T>>;
  placeholder?: string;
  searchFn?: (query?: string) => Promise<OptionsOrGroups<T, GroupBase<T>>>;
  value: T;
}

const Combobox = <T, >({
  defaultOptions,
  isLoading,
  label,
  name,
  onChange,
  options,
  placeholder,
  searchFn,
  value,
}: ComboboxProps<T>) => {
  const isAsync = !!searchFn;
  const debouncedSearchFn = debounce(searchFn, 250);
  const SelectComp = isAsync ? AsyncSelect : Select;

  return (
    <div>
      {label ? <label htmlFor={name}>{label}</label> : null}

      <SelectComp
        id={name}
        isClearable
        isLoading={isLoading}
        menuPosition="fixed"
        name={name}
        onChange={onChange}
        placeholder={placeholder}
        value={value}
        {...(!isAsync ? { options } : undefined)}
        {...(isAsync ? {cacheOptions: true} : undefined)}
        {...(isAsync && defaultOptions ? {defaultOptions} : undefined)}
        {...(isAsync ? {loadOptions: debouncedSearchFn} : undefined)}
      />
    </div>
  );
};

Combobox.displayName = 'Combobox';

export { Combobox };

