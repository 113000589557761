import type { ReactNode } from 'react';
import {HivebriteImportForm} from '…/app/w/workspace/audiences/audience/members/hivebrite/HivebriteImportForm.js';
import {HivebriteImportFormPicker} from '…/app/w/workspace/audiences/audience/members/hivebrite/HivebriteImportFormPicker.js';
import {
  HivebriteImportFormTitle,
} from '…/app/w/workspace/audiences/audience/members/hivebrite/HivebriteImportFormTitle.js';

interface HivebriteImportFormWrapperProps {
  onSubmit: () => void;
  hasHivebriteMembers: boolean;
}

const HivebriteImportFormWrapper = ({
  onSubmit,
  hasHivebriteMembers,
}: HivebriteImportFormWrapperProps): ReactNode => (
  <>
    <HivebriteImportFormTitle />

    {
      hasHivebriteMembers
        ? <HivebriteImportFormPicker onSubmit={onSubmit} />
        : <HivebriteImportForm onSubmit={onSubmit} />
    }
  </>
);

HivebriteImportFormWrapper.displayName = 'HivebriteImportFormWrapper';

export { HivebriteImportFormWrapper };
