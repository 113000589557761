import Button from 'form5/react/Button';
import Field, { type FieldProps } from 'form5/react/Field';
import Form from 'form5/react/Form';
import type { ReactNode } from 'react';
import { useState } from 'react';
import _set from 'lodash-es/set.js';

import { SubmitButton } from '…/app/common/SubmitButton/SubmitButton.jsx';
import { updateEngagement } from '…/app/w/workspace/engagements/engagement/gql/updateEngagement.op.mts';
import { PRODUCT_TO_GROUP_SIZE, STATUSES } from '…/app/w/workspace/engagements/constants.mts';
import type { STATUS } from '…/app/w/workspace/engagements/constants.mts';
import { Chip } from '…/app/w/workspace/common/Chip/Chip.tsx';

import { PRODUCT_TO_TYPE_DICT, PRODUCT_TYPES } from '…/app/deprecated/constants.mts';
import type { PRODUCT_TYPE } from '…/app/deprecated/constants.mts';

import genericClasses from './GenericEngagementForm.module.css';
import classes from './UpdateEngagementForm.module.css';

const GROUP_SIZE_EDITABLE_STATUSES = new Set([
  STATUSES.DRAFT,
  STATUSES.REVIEW,
  STATUSES.SCHEDULED,
  STATUSES.OPTINSOPEN,
]);

// **Note**: ORB-1633 - This is in some ways a duplicate of the CreateEngagementForm component.
export function UpdateEngagementForm(
  {
    engagementId,
    groupSize,
    name,
    onClose,
    product,
    setPageProps,
    status,
    workspaceId,
  }: {
    engagementId: string,
    groupSize: number,
    name: string,
    onClose: () => void,
    product: PRODUCT_TYPE,
    setPageProps: (data: any) => void,
    status: STATUS,
    workspaceId: string,
  },
) {
  const [data, setData] = useState({
    groupSize: `${groupSize}`,
    name,
  });
  const [isDirty, setDirty] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const onChange: FieldProps['onChange'] = ({ name: fieldName, value }) => {
    setData((prev) => _set({ ...prev }, fieldName, value));
  };

  const range = PRODUCT_TO_GROUP_SIZE[product];
  const canEditGroupSize = GROUP_SIZE_EDITABLE_STATUSES.has(status);

  return (
    <Form
      name="update-engagement-name-size"
      onDirty={() => setDirty(true)}
      onPristine={() => setDirty(false)}
      onSubmit={() => {
        setIsSaving(true);
        const engagementUpdateData = {
          name: data.name,
          ...(canEditGroupSize && {groupSize: +data.groupSize}),
        };
        return updateEngagement({
          engagement: engagementUpdateData,
          engagementId,
          workspaceId,
        })
          .then(() => {
            setIsSaving(false);
            setPageProps((prev) => ({
              ...prev,
              engagement: {
                ...prev.engagement,
                ...engagementUpdateData,
              },
            }));
            onClose();
          });
      }}
    >
      <header className={classes.Header}>
        <h1>Update Engagement</h1>

        <Chip>{PRODUCT_TO_TYPE_DICT[product]}</Chip>
      </header>

      <Field
        arrangement={Field.ARRANGEMENTS.STACKED}
        autoFocus
        defaultValue={data.name}
        fluid
        label="Name"
        minLength={3}
        name="name"
        onChange={onChange}
        placeholder="e.g. Women's Group Connections (November)"
        required
      />

      <p className="note">This engagement name is internal only (your members will never see it).</p>

      {/** **Note**: ORB-1633 - Can this be refactored to a specific component? */}
      {(product !== PRODUCT_TYPES.ONE_TO_ONE && canEditGroupSize && range) && (
        <fieldset className={genericClasses.RelatedFieldset}>
          <legend>Size</legend>

          <Button.Group className={genericClasses.StackedRadioGroup}>
            {(() => {
              const options: ReactNode[] = [];

              for (let i = range.min; i < range.max; i++) {
                options.push(
                  <Field
                    arrangement={Field.ARRANGEMENTS.STACKED}
                    checked={i === +data.groupSize}
                    id={`groupSize[${i}]`}
                    key={`${product}[${i}]`}
                    label={i}
                    name="groupSize"
                    onChange={onChange}
                    type="radio"
                    value={i}
                    variant={Field.VARIANTS.CTA}
                  />,
                );
              }

              return options;
            })()}
          </Button.Group>

          {product === PRODUCT_TYPES.GROUP && <p className="note">We recommend groups of 5-7 participants.</p>}
        </fieldset>
      )}

      <SubmitButton
        appearance={Button.APPEARANCES.PRIMARY}
        disabled={isSaving || !isDirty}
        isSubmitting={isSaving}
        type="submit"
      >Save
      </SubmitButton>
    </Form>
  );
}
UpdateEngagementForm.displayName = 'UpdateEngagementForm';
