import React, { useState } from 'react';
import Button from 'form5/react/Button';
import Form, { type OnSubmit } from 'form5/react/Form';
import Field from 'form5/react/Field';

import { SubmitButton } from '…/app/common/SubmitButton/SubmitButton.jsx';
import { Dialog } from '…/app/w/workspace/common/Dialog/Dialog.tsx';
import { copyToClipboard } from '…/app/common/copy-utils.mts';

import { createSession } from './createSession.op.mts';
import type { SessionResponse } from './apikey.d.ts';

import classes from './CreateApiKeyDialog.module.css';

interface CreateApiKeyDialogProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  setRefresh: (refresh: boolean) => void;
}

export const CreateApiKeyDialog: React.FC<CreateApiKeyDialogProps> = ({
  open,
  setRefresh,
  setOpen,
}) => {
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [token, setToken] = useState<string | null>(null);
  const [showForm, setShowForm] = useState<boolean>(true);
  const [errors, setErrors] = useState<unknown | null>(null);

  const handleSubmit: OnSubmit = async (data) => {
    setIsSaving(true);
    createSession({
      name: data.name as string,
      role: 'EDITOR',
    })
      .then((response: SessionResponse) => {
        if (response?.session?.token) {
          setToken(response.session.token);
          setShowForm(false);
        }
      })
      .catch(setErrors)
      .finally(() => {
        setRefresh(true);
        setIsSaving(false);
      });
  };

  const onClose = () => {
    setShowForm(true);
    setToken(null);
    setIsSaving(false);
    setOpen(false);
  };

  const onCopy = async () => copyToClipboard(token, 'API key');

  return (
    <Dialog
      backdropped
      open={!!open}
      setOpen={onClose}
    >
      {showForm
        ? (
          <Form name="create-api-key" onSubmit={handleSubmit}>
            <h2>Create an API Key</h2>

            <Field
              label="Name"
              name="name"
              placeholder="My Test API Key"
              required
              type="text"
            />

            {/** TODO: Do we have a generic way of displaying form errors? */}
            {errors && (
              <div>
                {errors instanceof Error ? errors.message : String(errors)}
              </div>
            )}

            <SubmitButton
              appearance={Button.APPEARANCES.AFFIRMING}
              disabled={isSaving}
              isSubmitting={isSaving}
              type="submit"
            >
              Create
            </SubmitButton>
          </Form>
        )
        : (
          <div>
            <p>
              <strong>Copy your API Key.</strong>{' '}

              Please save this secret key somewhere safe and accessible.{' '}

              For security reasons, you won&apos;t be able to view it again through your Orbiit account.{' '}

              If you lose this secret key, you&apos;ll need to generate a new one.
            </p>

            <div
              className={classes.ApiKeyContainer}
              onClick={onCopy}
            >
              {token}
            </div>
          </div>
        )}
    </Dialog>
  );
};

CreateApiKeyDialog.displayName = 'CreateApiKeyDialog';
