._SettingsPanel_1vpjj_1 {
  display: flex;
  flex-direction: column;
  gap: var(--grid-gutter);
  margin: calc(2 * var(--grid-gutter)) var(--grid-gutter);
  margin-bottom: 3rem;
  padding-top: 1.5rem;
}

@media (width <= 960px) {
  ._SettingsPanel_1vpjj_1 > h1 {
    margin-left: var(--grid-gutter);
    margin-right: var(--grid-gutter);
  }
}

@media (width > 960px) {
  ._SettingsPanel_1vpjj_1 {
    margin-left: auto;
    margin-right: auto;
    width: 80ch;
  }
}

._Section_1vpjj_25 {
  background: var(--background-card);
  border: 1px solid var(--border-colour-subtle);
  border-radius: var(--default-border-radius);
  box-shadow: var(--box-shadow-card);
  display: flex;
  flex-direction: column;
  gap: inherit;
  margin-top: 1rem;
}

@media (width > 960px) {
  ._Section_1vpjj_25 {
    padding: calc(2 * var(--grid-gutter));
  }

  ._Section_1vpjj_25 > button {
    place-self: end;
  }
}
