import {InfoBanner} from '…/app/w/workspace/common/InfoBanner/InfoBanner.js';

interface HivebriteSourceInformationProps {
  numberOfMembers: number;
}

const HivebriteSourceInformation = ({numberOfMembers}: HivebriteSourceInformationProps ) => (
  <div className="mt-3">
    <p className="font-bold mb-1">
      {numberOfMembers} members found.
    </p>


    <InfoBanner type="info">
      <span>
        Users that already exist in the audience will be updated with the latest data from Hivebrite.
      </span>
    </InfoBanner>
  </div>
);

HivebriteSourceInformation.displayName = 'HivebriteSourceInformation';

export {HivebriteSourceInformation};
