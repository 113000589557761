import {ReactNode} from 'react';
import {IIcon} from '…/app/w/workspace/common/Icon/Icon.js';

type InfoBannerType = 'info' | 'warning' | 'error' | 'success';

interface InfoBannerProps {
  type: InfoBannerType;
  children: ReactNode;
}

const getIcon = (type: InfoBannerType): string => {
  switch (type) {
    case 'info':
      return 'info';
    case 'warning':
    case 'error':
    case 'success':
    default:
      return 'rings';
  }
};

const InfoBanner = ({ type, children }: InfoBannerProps) => {
  const iconName = getIcon(type);

  return (
    <div className="py-3 px-3 flex gap-2 bg-[#ECF0FF] items-start">
      <span className="text-[#4733FF] relative bottom-0.5">
        {/* @ts-ignore  */}
        <IIcon label={`${type} icon`} name={iconName} />
      </span>

      <div>
        {children}
      </div>
    </div>
  );
};

InfoBanner.displayName = 'InfoBanner';

export { InfoBanner };
