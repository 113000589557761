import {
  useEffect,
  useState,
} from 'react';
import { clsx } from 'clsx';

import Button from 'form5/react/Button';
import Field from 'form5/react/Field';
import Form from 'form5/react/Form';

import { ENGAGEMENT_STATUS_STEP } from '…/app/deprecated/constants.mts';

import type { WalledRouteParams } from '…/app/route-params.mts';
import { useStore } from '…/app/state/useStore.mts';
import {
  Bureau,
  Drawer,
  Shelf,
} from '…/app/common/Drawer/Drawer.tsx';
import { Chip } from '…/app/w/workspace/common/Chip/Chip.tsx';
import type { Sort } from '…/app/common/Table/Table.tsx';
import { Table } from '…/app/common/Table/Table.tsx';
import {
  PAGE_ITEM_LIMIT,
  type Search,
  paginationFactory,
  resetPaginationOnRefine,
} from '…/app/common/pagination/factories.mts';
import { usePagination } from '…/app/common/pagination/usePagination.mts';
import { Popover } from '…/app/w/workspace/common/Popover/Popover.tsx';
import { CTA } from '…/app/w/workspace/common/CTA/CTA.tsx';
import { Dialog } from '…/app/w/workspace/common/Dialog/Dialog.tsx';
import { FilterIcon } from '…/app/common/filtering/FilterIcon.tsx';

import { downloadEngagementSection } from '…/app/deprecated/utils/shared/data/downloads.mjs';

import { tableHeadings, transformOptInsToTabularData } from './transformOptInsToTabularData.tsx';
import { getEngagementOptIns, getFilterCount } from './getOptInForms.op.mts';
import type { SortableFields } from './getOptInForms.op.mts';
import { DeleteOptInsForm } from './DeleteOptInsForm.tsx';
import type { OptInsFilters } from './FiltersForm.tsx';
import { MemoizedOptInsFiltersForm } from './FiltersForm.tsx';
import { OptInFormDrawer } from './OptInFormDrawer.tsx';
import type {
  OptInForm,
  WidgetConfig,
} from './optin-forms.d.ts';

import { useViewState } from '…/app/w/workspace/engagements/engagement/state.mts';

import type { ScheduledEngagement } from '../../Engagement.d.ts';
import { getFormAppLink } from '../participantFormUtils.ts';

import classes from './OptInFormsPage.module.css';
import { useRefinementParams } from '…/app/common/refinement/useRefinementParams.mts';


function convertFilterstoQueryParams(filters: OptInsFilters, search: Search): Record<string, string> {
  const filterQueryParams: Record<string, string> = {};
  if (filters.DATETIME.length > 0) {
    filterQueryParams['date'] = filters.DATETIME.join(',');
  }
  if (filters.SELECTED_OPTIONS.length > 0) {
    filterQueryParams['selectedOptions'] = filters.SELECTED_OPTIONS.join(',');
  }
  if (filters.STATUS.length > 0) {
    filterQueryParams['status'] = filters.STATUS.join(',');
  }
  if (search.q) {
    filterQueryParams['search'] = search.q;
  }
  return filterQueryParams;
}

export function EngagementOptins() {
  const [
    {
      engagement,
      params,
    },
  ] = useViewState<ScheduledEngagement>();
  const { workspaceId } = params as WalledRouteParams;
  const user = useStore((data) => data.user);
  const workspace = useStore((data) => data.workspaces[workspaceId]);

  const {
    count: [count, setCount],
    cursors,
    page: [page, setPage],
    records: [data, setData],
  } = usePagination<OptInForm>();

  const {
    filters: [filters, setFilters],
    search: [search, setSearch],
    sort: [sort, setSort],
  } = useRefinementParams({
    filters: {
      DATETIME: new Array<ISO_8601>(),
      SELECTED_OPTIONS: new Array(),
      STATUS: new Array(),
    },
    search: { q: '' } as Search,
    sort: {
      ascending: false,
      fieldName: 'optInDate',
    },
  });

  const [widgetConfig, setWidgetConfig] = useState<WidgetConfig>();

  const [loading, setLoading] = useState<boolean>(true);

  const isDeleteOptInFormDisabled = ENGAGEMENT_STATUS_STEP[engagement?.status] > 1 || engagement?.status === 'MATCHING';
  const isCopyFormAppLinkDisabled = isDeleteOptInFormDisabled;
  const optInFormURL = getFormAppLink({
    engagement,
    user,
    workspace,
  });

  useEffect(() => {
    setLoading(true);

    const pagination = paginationFactory<OptInForm, 'optInForms'>(
      'optInForms',
      {
        cursors,
        direction: page.direction,
        filters,
        limit: page.limit,
        reset: page.reset,
        search: search.q,
      },
      {
        setCount: setCount,
        setRecords: setData,
      },
    );

    getEngagementOptIns(
      workspaceId,
      engagement.id,
      {
        ...pagination.props,
        sort,
      },
    )
      .then(pagination.then)
      .finally(() => setLoading(false));
  }, [
    filters,
    page,
    search,
    sort,
  ]);

  const removeOptInFromState = (id: OptInForm['id']) => {
    const newOptInForms = new Map(data);
    newOptInForms.delete(id);
    setData(newOptInForms);
    setCount(count - 1);
    setWidgetConfig(undefined);
  };

  const setRefineCriteria = resetPaginationOnRefine<SortableFields, EngagementFilters>(
    setPage,
    setFilters,
    setSearch,
    setSort,
  );
  const filtersCount = getFilterCount(filters);

  const filterQueryParams = convertFilterstoQueryParams(filters, search);

  return (
    <main
      className="ContentContainer"
      data-testid="EngagementOptInFormsPage"
    >
      <Bureau className={classes.Bureau}>
        <Shelf className="ListPage">
          <header className={clsx('SplitHeader', classes.ExtraMarginBottom)}>
            <h1 className="SplitHeader">
              Opt-In Forms

              {' '}

              <Chip count={count} />
            </h1>

            <fieldset className="SplitHeader" disabled={loading}>
              <FilterIcon
                count={filtersCount}
                onClick={() => setWidgetConfig({
                  data: {
                    filters,
                    onSubmit: (_filters: OptInsFilters) => {
                      setRefineCriteria({
                        filters: _filters,
                        search,
                        sort,
                      });
                      setWidgetConfig(undefined);
                    },
                  },
                  key: 'filters',
                  name: 'filters',
                  type: 'drawer',
                })}
                testId="OptInsTableFilterButton"
              />

              <Form
                name="search"
                onReset={() => setRefineCriteria({
                  filters,
                  search: { q: '' },
                  sort,
                })}
                onSubmit={(_search: Search) => setRefineCriteria({
                  filters,
                  search: _search,
                  sort,
                })}
                role="search"
              >
                <Field
                  arrangement={Field.ARRANGEMENTS.STAND_ALONE}
                  defaultValue={search.q}
                  label={null}
                  name="q"
                  placeholder="Search"
                  type="search"
                />
              </Form>

              <Popover
                anchor={({ className, ...props }) => (
                  <Button
                    {...props}
                    appearance={Button.APPEARANCES.BASIC}
                    aria-label="context menu"
                    className={clsx(className, 'ContextMenuIcon')}
                  />
                )}
                position={Popover.POSITIONS.BOTTOM_RIGHT}
                type={Popover.TYPES.MENU}
              >
                <CTA
                  disabled={data.size === 0}
                  guise={CTA.GUISES.LINK}
                  menuitem=""
                  to={() => downloadEngagementSection(workspaceId, engagement, 'opt-in-forms')}
                >Download CSV
                </CTA>

                <CTA
                  disabled={Object.keys(filterQueryParams).length === 0}
                  guise={CTA.GUISES.LINK}
                  menuitem=""
                  to={() => downloadEngagementSection(workspaceId, engagement, 'opt-in-forms', filterQueryParams)}
                >Download CSV with filters
                </CTA>

                <CTA
                  disabled={isCopyFormAppLinkDisabled}
                  guise={CTA.GUISES.LINK}
                  menuitem=""
                  to={() => null}
                >
                  <a
                    href={(new URL(optInFormURL)).href}
                    menuitem=""
                    referrerPolicy="no-referrer"
                    rel="noreferrer"
                    target="_blank"
                  >Opt-In Form
                  </a>
                </CTA>
              </Popover>
            </fieldset>
          </header>

          <Table
            className={classes.Table}
            emptyText={
              (filtersCount === 0 && search.q === '')
                ? 'No Opt-In Forms have come in yet.'
                : 'No Opt-In Forms match your criteria.'
            }
            headings={tableHeadings}
            loading={loading}
            onSort={(_sort: Sort<SortableFields>) => setRefineCriteria({
              filters,
              search,
              sort: _sort,
            })}
            pagination={{
              onPage: setPage,
              page,
              total: count,
            }}
            records={transformOptInsToTabularData(
              data,
              {
                limit: PAGE_ITEM_LIMIT,
                page: page.number,
              },
              {
                isDeleteOptInFormDisabled,
                setWidgetConfig,
              },
            )}
            sort={sort}
          />
        </Shelf>

        <Drawer
          className={classes.Drawer}
          modal="backdropped"
          onClose={() => setWidgetConfig(undefined)}
          open={widgetConfig?.type === 'drawer'}
        >
          {(widgetConfig?.type === 'drawer' && !!widgetConfig?.data) && (
            <>
              {(widgetConfig.data?.optInForm && widgetConfig.name === 'form') && (
                <OptInFormDrawer
                  engagement={engagement}
                  optInForm={widgetConfig.data.optInForm}
                />
              )}

              {(widgetConfig.data?.filters && widgetConfig.name === 'filters') && (
                <MemoizedOptInsFiltersForm
                  filters={widgetConfig.data.filters}
                  questions={engagement?.optInQuestions ?? []}
                  setFilters={widgetConfig.data.onSubmit}
                />
              )}
            </>
          )}
        </Drawer>
      </Bureau>

      <Dialog
        backdropped
        open={widgetConfig?.type === 'dialog'}
        setOpen={setWidgetConfig}
      >
        {widgetConfig?.type === 'dialog' && (
          <DeleteOptInsForm
            {...widgetConfig?.data}
            key={widgetConfig?.key}
            onSubmit={removeOptInFromState}
          />
        )}
      </Dialog>
    </main>
  );
}

EngagementOptins.displayName = 'Engagement Opt-In Forms';

const handle = {
  sidebar: true,
  subheader: true,
  title: 'Engagement Optins',
};

export {
  EngagementOptins as Component,
  handle,
};
