mutation VerifyWhitelabelingDomain($workspaceId: ID!) {
  verifyWhitelabelingDomain(workspaceId: $workspaceId) {
    id
    whitelabeling {
      domain
      sendgrid {
        dkim1 {
          valid
        }
        dkim2 {
          valid
        }
        id
        lastUpdatedAt
        mailCname {
          valid
        }
      }
    }
  }
}
