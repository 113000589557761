import { gqlOperation } from '…/app/common/rpc/gqlOperation.mts';

import type { EngagementEmail } from '…/app/w/workspace/engagements/Engagement.d.ts';

import { UPDATE_EMAIL } from './updateEmail.gql.mjs';


export function updateEmail({
  email,
  emailId,
}: {
  email: Partial<EngagementEmail>,
  emailId: ObjectId,
}) {
  return gqlOperation<{ email: EngagementEmail }>(UPDATE_EMAIL, {
    email,
    emailId,
  })
    .then((data) => data.email);
}
