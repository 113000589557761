/* eslint-disable sort-keys */

import _sortBy from 'lodash-es/sortBy.js';
import { DateTime, Settings } from 'luxon';
import type {
  DateTimeFormatOptions,
  DateTimeOptions,
  Duration,
} from 'luxon';
import { getWeekStartByLocale } from 'weekstart';


export const getNow = () => DateTime.now();

export const ago = (datetimeISO: ISO_8601) => DateTime
  .fromISO(datetimeISO)
  .toRelative();

export const SIMPLE_DATETIME_OPTS = {
  includeOffset: false,
  suppressMilliseconds: true,
};

/**
 * `input[type="date"]` → `input[type="datetime-local"]`
 *
 * Use this when consuming a date form field's value in a datetime form field (ex validation).
 */
export const deriveDateTimeFromDate = (datetimeUTC: ISO_8601 | DateTime) => {
  if (!datetimeUTC) return;

  const dt = typeof datetimeUTC === 'string' ? DateTime.fromISO(datetimeUTC) : datetimeUTC;

  return dt
    .set({
      millisecond: 0,
      second: 0,
    }) // Luxon's "suppress" options only work when those values are 0
    .toISO(SIMPLE_DATETIME_OPTS);
};

/**
 * `input[type="datetime-local"]` → `input[type="date"]`
 *
 * Use this when consuming a datetime form field's value in a date form field (ex validation).
 */
export const deriveDateFromDateTime = (datetimeUTC: ISO_8601 | DateTime) => {
  if (!datetimeUTC) return;

  const dt = typeof datetimeUTC === 'string' ? DateTime.fromISO(datetimeUTC) : datetimeUTC;

  return dt.toISODate();
};

/**
 * OnSubmit: Use this to convert actual UTC datetimes to fake ones to save in the Db.
 */
export const adjustTimezoneToUTC = (
  datetimeISO: ISO_8601,
  zone: TimezoneISO,
  times: Parameters<DateTime['set']>[0] = {},
) => DateTime
  .fromISO(datetimeISO.replace('Z', ''), { zone })
  .set(times)
  .toUTC()
  .toISO(SIMPLE_DATETIME_OPTS);

export const adjustUTCtoTimezone = (
  datetimeISO: ISO_8601,
  zone: TimezoneISO,
) => DateTime
  .fromISO(datetimeISO, { zone });

/**
 * OnLoad: Use this to convert "UTC" datetimes saved in the Db to actual ones.
 */
export const adjustUTCtoTimezoneISO = (
  datetimeISO: ISO_8601 | DateTime,
  zone: TimezoneISO,
  output: 'date' | 'datetime',
) => {
  if (!datetimeISO) return;

  const dt = typeof datetimeISO === 'string'
    ? adjustUTCtoTimezone(datetimeISO, zone)
    : datetimeISO;

  switch (output) {
    case 'date': return dt.toISODate();
    case 'datetime': return dt.toISO(SIMPLE_DATETIME_OPTS);
  }
};

export const convertIsoDateToDateTime = (
  isoDate: DateTime | ISO_8601,
  timezoneISO: TimezoneISO,
  hour = 0,
  minute = 0,
) => {
  const dt = typeof isoDate === 'string' ? DateTime.fromISO(isoDate) : isoDate;

  return dt
    .set({
      hour,
      minute,
      second: 0,
    })
    .setZone(timezoneISO, { keepLocalTime: true });
};

export const filterAndSortDateStrings = (dates: ISO_8601[]) => {
  const filteredDates = dates.filter((isoDateString) => isoDateString && isoDateString !== '');
  const orderedDates = _sortBy(filteredDates, (isoDateString) => isoDateString);
  return orderedDates;
};

/**
 * For documentation on the formatting
 *  - https://moment.github.io/luxon/api-docs/index.html#datetimetoformat
 *  - https://moment.github.io/luxon/#/formatting?id=table-of-tokens
 *
 * @param emailData
 * @param label - e.g. Invite
 * @param dateFormat - EEEE, LLL. d
 * @param timezoneAbbreviation - EST
 */
export const formatEngagementEmailDatetime = (
  emailData: Record<string, any>,
  label: string,
  dateFormat: Parameters<DateTime['toFormat']>[0],
  timezoneAbbreviation: Parameters<typeof adjustAndFormatDate>[3],
) => {
  if (emailData?.isDisabled) {
    return `The ${label} Email is disabled`;
  }
  if (emailData?.sendAt && emailData.timezone) {
    return adjustAndFormatDate(emailData.sendAt, emailData.timezone, 'datetime', timezoneAbbreviation);
  }
  return 'Select the dates for your engagement';
};

export const adjustAndFormatDate = (
  date: string,
  timezone: string,
  dateType: Parameters<typeof adjustUTCtoTimezoneISO>[2],
  tzAbbrv?: string,
) => {
  const includeTime = dateType === 'datetime';
  const adjustedDate = adjustUTCtoTimezoneISO(date, timezone, dateType);
  return `${formatDate(adjustedDate, undefined, includeTime)}${tzAbbrv ? ` (${tzAbbrv})` : ''}`;
};

export const DATE_FORMAT_OPTS: DateTimeFormatOptions = {
  day: '2-digit',
  month: 'short',
  year: 'numeric',
};
export const DATETIME_FORMAT_OPTS: DateTimeFormatOptions = {
  ...DATE_FORMAT_OPTS,
  minute: '2-digit',
  hour: '2-digit',
};

/**
 * @param dateValue - iso date string
 */
export const formatDate = (
  datetimeUTC?: DateTime | ISO_8601 | null,
  timezoneISO?: TimezoneISO,
  includeTime = false,
): ISO_8601 | null => {
  if (!datetimeUTC) { return null }

  const formatOpts: DateTimeFormatOptions = includeTime ? DATETIME_FORMAT_OPTS : DATE_FORMAT_OPTS;

  const dt = datetimeUTC instanceof DateTime
    ? datetimeUTC
    : DateTime.fromISO(datetimeUTC, {
      ...timezoneISO && { zone: timezoneISO }, // FIXME: Use adjustUTCtoTimezoneISO
    });

  return dt.isValid
    ? dt.toLocaleString(formatOpts)
    : null;
};

export const nextTuesdayDate = (isoDateString = DateTime.local().toISO()!, timezone = 'local') =>
/*
   * - Date selection is restricted to **only Tuesdays**
   * - A Tuesday can be selected until midnight Saturday of the previous week.
   * - Use 'End of Week' function.
   * - Ex: On Friday April 16 @ 11pm , I should still have the option to choose Tuesday April 20 as a start date
   */

  DateTime
    .fromISO(isoDateString)
    .setZone(timezone)
    .plus({ days: 1 }) // end of week is Sunday, so if we're on Sunday go to the next week
    .endOf('week') // Go to he end of that week
    .plus({ days: 2 }) // the next date is the Tuesday the week after
    .startOf('day') // reset to the start of the day in that timezone
    .toISO();

export const nextThursdayDate = (isoDateString = DateTime.local().toISO()!, timezone = 'local') =>
  /*
   * - Date selection is restricted to **only Thursday**
   * - A Thursday can be selected until midnight Saturday of the previous week.
   * - Use 'End of Week' function.
   * - Ex: On Friday April 16 @ 11pm , I should still have the option to choose Thursday April 22 as a start date
   */
  DateTime
    .fromISO(isoDateString)
    .setZone(timezone)
    .plus({ days: 1 }) // end of week is Sunday, so if we're on Sunday go to the next week
    .endOf('week') // Go to he end of that week
    .plus({ days: 4 }) // the next date is the Thursday the week after
    .startOf('day') // reset to the start of the day in that timezone
    .toISO();


export const getDateTimeObject = (
  date: DateTime | ISO_8601,
  opts: DateTimeOptions = {},
) => typeof date === 'string'
  ? DateTime.fromISO(date, opts)
  : date;

const isFriday = (date: DateTime | ISO_8601) => getDateTimeObject(date).weekday === 5;

export const isSaturday = (date: DateTime | ISO_8601) => getDateTimeObject(date).weekday === 6;

const isSunday = (date: DateTime | ISO_8601) => getDateTimeObject(date).weekday === 7;

export const isWeekendDay = (date: DateTime | ISO_8601) => isSaturday(date) || isSunday(date);

export const isFridayOrSaturday = (date: DateTime | ISO_8601) => isFriday(date) || isSaturday(date);

export const shouldStartOnSunday = () => getWeekStartByLocale(Settings.defaultLocale) === 0;

/**
 *
 * @param subject The datetime to test.
 * @param window
 * @param window.threshold The number amount within which to test.
 * @param window.unit The unit of time (eg 'minutes') of threshold.
 * @param now The datetime against which subject's closeness is tested.
 */
export const isApproachingTargetTime = (
  s: ISO_8601,
  {
    threshold,
    unit = 'minutes',
  }: {
    threshold: number,
    unit?: Parameters<Duration['as']> [0],
  },
  n = DateTime.utc(),
) => {
  const subject = DateTime.fromISO(s, { zone: 'utc' });
  const now = getDateTimeObject(n, { zone: 'utc' });

  const diff = subject
    .diff(now)
    .as(unit);

  if (diff > 0) { // subject is in future
    if (threshold > 0) return true; // looking forward
    return Math.abs(threshold) > diff;
  }
  // subject is in the past
  if (threshold < 0) return true; // looking backward
  return Math.abs(threshold) > diff;
};

/**
 * Generate an array of week days from a given date.
 */
export const generateWeekDaysFromDate = (date: DateTime) => [1, 2, 3, 4, 5].map((weekday) => date.set({ weekday }));

/**
 * Generate an array of remaining week days from a given date.
 * @param {Array<DateTime>} dates
 * @returns {Array<DateTime>}
 */
export const generateOtherWeekDaysFromDate = (dates: DateTime[]) => {
  const lastSelectedDate = dates[dates.length - 1];
  const allWeekDates = generateWeekDaysFromDate(lastSelectedDate);
  const selectedWeekDays = dates.map((dt) => dt.weekday);
  return allWeekDates.filter((dt) => selectedWeekDays.indexOf(dt.weekday) === -1);
};

export const shiftEmailDates = (emailData: any, timezoneISO: TimezoneISO, shiftByDays: number) => ({
  sendAt:
      !emailData.isDisabled && emailData?.sendAt
        ? adjustUTCtoTimezone(emailData.sendAt, timezoneISO)
          .plus({ days: shiftByDays })
          .toISO()
        : null,
});

export const getDaysShift = (numWeeks: number, source: DateTime, target: DateTime): number => {
  const weeks = numWeeks + Math.round(target.diff(source, ['days']).days / 7);
  return 7 * weeks;
};

export const formatRelativeDate = (date: DateTime): string => {
  const diff = date.diffNow(['days', 'hours', 'minutes']).toObject();
  const relative = date.toRelativeCalendar({ unit: 'days' }) as string; // https://github.com/moment/luxon/issues/394

  if (diff?.days) return relative;

  if (diff.days === 0) {
    if (diff.hours === 0) {
      const relativeMinutes = date.toRelative({ unit: 'minutes' }) as string;
      if (relativeMinutes === '0 minutes ago') return 'just now';
      return relativeMinutes;
    }

    if (diff.hours && diff.hours > -12) {
      const relativeHours = date.toRelative({ unit: 'hours' }) as string;
      return relativeHours;
    }
  }

  return relative;
};
