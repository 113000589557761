import { clsx } from 'clsx';
import _get from 'lodash-es/get.js';
import { toast } from 'sonner';

import { useStore } from '…/app/state/useStore.mts';
import { Tabs } from '…/app/common/Tabs/Tabs.tsx';
import { useEditorRole } from '…/app/common/permissions/useRoles.mjs';

import { SETUP_SECTIONS } from '…/app/w/workspace/engagements/constants.mts';
import { useViewState } from '…/app/w/workspace/engagements/engagement/state.mts';
import type { Engagement } from '…/app/w/workspace/engagements/Engagement.d.ts';

import { updateEngagement } from '../../gql/updateEngagement.op.mts';

import { getFormAppLink } from '../../participantFormUtils.ts';

import { Advice } from '../Advice.tsx';
import { setupPageConfig } from '../setupPageConfig.mts';
import subpageClasses from '../EngagementSubPage.module.css';

import type { FormData, Question } from './ParticipantQuestions.d.ts';
import { mapQuestions } from './mapQuestions.ts';
import { questionConfigGetters } from './questions.config.mts';
import { setParticipantQuestion } from './setQuestion.op.mts';
import { ParticipantSetupForm } from './ParticipantSetupForm.tsx';


const tabs = new Map([
  [{ section: 'setup' }, {
    end: true,
    routeTemplate: '/w/:workspaceId/engagements/:engagementId/:section',
    title: '← Overview',
  }],
  [{ subSection: SETUP_SECTIONS.SIGNUP }, setupPageConfig[SETUP_SECTIONS.SIGNUP]],
  [{ subSection: SETUP_SECTIONS.FEEDBACK }, setupPageConfig[SETUP_SECTIONS.FEEDBACK]],
  [{ subSection: SETUP_SECTIONS.INVITATION }, { title: 'Emails →' }],
]);

export function ParticipantFormSetupPage() {
  const [
    {
      className,
      engagement,
      params,
    },
    setPageProps,
  ] = useViewState();
  const {
    engagementId,
    subSection,
    workspaceId,
  } = params;

  const user = useStore((data) => data.user);
  const workspace = useStore((data) => data.workspaces[workspaceId]);
  const isEditor = useEditorRole();

  const {
    advice,
    dataPath,
  } = setupPageConfig[subSection];
  const questionConfig = questionConfigGetters[subSection](engagement.product);

  async function onSubmit(state) {
    const { custom: customQuestions, ...builtinQuestions } = state.questions;

    const parallelQuestions = Object.values(builtinQuestions);
    const parallelUpdates = new Array(parallelQuestions.length);

    // FIXME: This results in like 15 requests (the API requires questions be updated
    // individually); find some way to consolidate these into 1 request.
    for (const { 0: idx, 1: question } of parallelQuestions.entries()) {
      parallelUpdates[idx] = setParticipantQuestion({
        engagementId,
        question,
        type: subSection,
        workspaceId,
      });
    }

    // FIXME: This is a quick fix to deal with the poor design and thus constraints around
    // custom questions (specifically related to their stepName).
    let customIdx = 1;
    for (const customQ of customQuestions) {
      await setParticipantQuestion({ // eslint-disable-line no-await-in-loop
        engagementId,
        question: composeCustomStepName(customQ, customIdx),
        type: subSection,
        workspaceId,
      });
      if (!customQ.isDeleted) customIdx++;
    }

    await Promise.all(parallelUpdates)
      .catch((err: AggregateError) => {
        toast.error(err.errors?.[0].message);
      });

    return updateEngagement({
      engagement: {
        description: state.description,
        title: state.title,
      },
      engagementId,
      workspaceId,
    })
      .then((result: Engagement) => {
        // This is also necessary for when switching between forms (signup vs feedback): it does not
        // invoke the router, so the setup container does not run (and no network request is made),
        // so if this is not set, the parent's data is stale, and thus the next time this mounts, it
        // would receive a stale `engagement`.
        setPageProps((prev) => ({
          ...prev,
          engagement: result,
        }));
        toast.success('Form setup saved!');
      })
      .catch((err: AggregateError) => {
        toast.error(err.errors?.[0].message);
      });
  }

  return (
    <main
      className={clsx(className, subpageClasses.SubPage)}
      data-testid={`ParticipantFormSetupPage_${subSection}`}
    >
      <section className={clsx(subpageClasses.Section, subpageClasses.EditableSection)}>
        <Tabs
          params={params}
          routeTemplate="/w/:workspaceId/engagements/:engagementId/:section/:subSection"
          tabs={tabs}
        />

        <ParticipantSetupForm
          config={questionConfig}
          data={composeData(engagement, dataPath!)}
          disabled={engagement.isLive || !isEditor}
          name={subSection}
          onSubmit={onSubmit}
          previewUrl={getFormAppLink({
            engagement,
            formType: subSection,
            user,
            workspace,
          })}
          type={subSection}
        />
      </section>

      <Advice {...advice} className={clsx(subpageClasses.Section, subpageClasses.Sidebar)} />
    </main>
  );
}
ParticipantFormSetupPage.displayName = 'ParticipantFormSetupPage';

export {
  ParticipantFormSetupPage as Component,
};

/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
function composeData(rawData: any, dataPath: string): FormData {
  const loadedData = _get(rawData, dataPath) ?? new Array();

  return {
    description: rawData.description,
    questions: mapQuestions(loadedData),
    title: rawData.title,
  };
}

function composeCustomStepName(question: Question, idx: number) {
  question.stepName = `custom_${idx}`;

  return question;
}
