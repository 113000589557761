import Button from 'form5/react/Button';
import {
  type Dispatch,
  type SetStateAction,
  useState,
} from 'react';
import { toast } from 'sonner';

import { SubmitButton } from '…/app/common/SubmitButton/SubmitButton.jsx';

import type { Engagement } from '../Engagement.d.ts';
import { archiveEngagements } from './gql/archiveEngagements.op.mjs';
import { STATUSES } from '../constants.mts';

export function ArchiveEngagementForm(
  {
    engagement: {
      status,
      name,
    },
    engagementId,
    setCount,
    setEngagements,
    setOpen,
    workspaceId,
  }: {
    engagement: Engagement,
    engagementId: string,
    setCount: Dispatch<SetStateAction<number>>,
    setEngagements: Dispatch<SetStateAction<Map<Engagement['id'], Engagement>>>,
    setOpen: Dispatch<SetStateAction<boolean>>,
    workspaceId: string,
  },
) {
  const [isSaving, setIsSaving] = useState(false);

  const editable = engagementCanBeArchived(status);

  function onConfirm() {
    setIsSaving(true);

    const promise = archiveEngagements({
      engagementIds: engagementId,
      workspaceId,
    })
      .then(() => {
        setEngagements((prev) => {
          const result = new Map(prev);
          result.delete(engagementId);
          return result;
        });
        setCount((prev) => prev - 1);
        setOpen(false);
      })
      .finally(() => setIsSaving(false));

    toast.promise(promise, {
      error: (err: AggregateError | Error) => err?.errors?.[0].message || err.message,
      loading: 'Deleting engagement…',
      success: `“${name}” deleted`,
    });
  }

  return (
    <>
      <h2>Delete Engagement</h2>

      <p>Are you sure you want to delete the engagement “{name}”?</p>

      <SubmitButton
        appearance={Button.APPEARANCES.DANGER}
        disabled={isSaving || !editable}
        isSubmitting={isSaving}
        onClick={onConfirm}
        type="submit"
      >Delete
      </SubmitButton>
    </>
  );
}
ArchiveEngagementForm.displayName = 'ArchiveEngagementForm';

export const engagementCanBeArchived = (
  status: Engagement['status'],
) => status === STATUSES.DRAFT || status === STATUSES.REVIEW || status === STATUSES.SCHEDULED;
