query GetWorkspaceWhitelabeling($workspaceId: ID!) {
  workspace(workspaceId: $workspaceId) {
    id
    whitelabeling {
      domain
      fromEmailAddress
      sendgrid {
        dkim1 {
          data
          host
          reason
          type
          valid
        }
        dkim2 {
          data
          host
          reason
          type
          valid
        }
        id
        lastUpdatedAt
        mailCname {
          data
          host
          reason
          type
          valid
        }
        subUser
      }
    }
  }
}
