import { clsx } from 'clsx';

import { formatDate } from '…/app/common/dateUtils.mts';

import classes from './EmailStatsCard.module.css';

const SHOW_EVENTS = new Array(
  'processed',
  'dropped',
  'deferred',
  'delivered',
  'bounce',
  'open',
  'click',
);

type EventTypes = typeof SHOW_EVENTS[number];

export function EmailStatsCard({ obj }: { obj: any }) {
  if (!obj) return null;

  const {
    category, evaluated_at: evaluatedAt, count: eventCounts,
  }: { category: string, evaluated_at: string, count: any } = obj;

  const max = eventCounts?.processed ?? 0;

  return (
    <div className={classes.Container}>
      <h3 className={classes.CardTitle}>
        {category.replace('engagement-', '')} Email
      </h3>

      <p className={classes.CardDate}>
        Last checked: {formatDate(evaluatedAt, null, true)}
      </p>

      <div>
        <div className={classes.FlexCol}>
          {SHOW_EVENTS.map((label: EventTypes) => {
            const count = eventCounts?.[label] ?? 0;
            return (
              <div className={clsx(classes.FlexRow, classes.EmailStatsRow)} key={`${category}-${label}`}>
                <div className={classes.FlexRow}>
                  <div style={{ textTransform: 'capitalize' }}>{label}</div>

                  <span className={classes.Stat}>
                    {(100.0 * count / max).toFixed(1)}%
                  </span>
                </div>

                {/* <div className={classes.SkeletonLoader} /> */}

                <div>{count}</div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
EmailStatsCard.displayName = 'EmailStatsCard';
