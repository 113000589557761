import type { HTTPError } from 'ky';

import { gqlOperation } from '…/app/common/rpc/gqlOperation.mts';

import type { EngagementSchedule } from '../../Engagement.d.ts';

import { GET_ENGAGEMENT } from './getEngagement.gql.mjs';
import { engagementEmailsMigration } from './engagementEmailsMigration.ts';

export function getEngagement({ engagementId, workspaceId }: { engagementId: ObjectId, workspaceId: ObjectId }) {
  return gqlOperation<{
    engagement: EngagementSchedule,
  }>(GET_ENGAGEMENT, {
    engagementId,
    workspaceId,
  })
    .catch((err: HTTPError) => {
      throw Object.assign(
        new AggregateError(
          new Array(err),
          'Couldn’t retrieve engagement',
        ),
        { code: err?.response.status },
      );
    })
    .then(({ engagement }) => engagementEmailsMigration(engagement));
}
