import {useEffect, useState} from 'react';
import { useParams } from 'react-router-dom';
import {Skeleton} from '…/app/common/Skeleton/Skeleton.js';
import {
  ExistingImport,
  getExistingImports,
} from '…/app/w/workspace/audiences/audience/members/hivebrite/queries/getExistingImports.op.mjs';
import {Radios} from '…/app/common/Radios/Radios.js';
import {RadioProps} from '…/app/common/Radios/Radio.js';
import {SEGMENT_LABELS} from '…/app/w/workspace/audiences/audience/members/hivebrite/constants.js';
import {formatDate} from '…/app/common/dateUtils.mjs';
import {
  getSourceInformation,
} from '…/app/w/workspace/audiences/audience/members/hivebrite/queries/getSourceInformation.op.mjs';
import {HivebriteSource} from '…/app/w/workspace/audiences/audience/members/hivebrite/types.js';
import {
  HivebriteSourceInformation,
} from '…/app/w/workspace/audiences/audience/members/hivebrite/HivebriteSourceInformation.js';
import Form, {type OnSubmit} from 'form5/react/Form';
import Button from 'form5/react/Button';
import {SubmitButton} from '…/app/common/SubmitButton/SubmitButton.jsx';
import {
  importHivebriteMembers,
} from '…/app/w/workspace/audiences/audience/members/hivebrite/queries/importMembers.op.mjs';

interface HivebriteExistingImportsFormProps {
  onSubmit: () => void;
}

const HivebriteExistingImportsForm = ({ onSubmit }: HivebriteExistingImportsFormProps) => {
  const { audienceId } = useParams();
  const [isImportingMembers] = useState<boolean>(false);
  const [imports, setImports] = useState<ExistingImport[]>([]);
  const [areImportsLoading, setAreImportsLoading] = useState(false);
  const [selectedImport, setSelectedImport] = useState<string | null>(null);
  const [sourceData, setSourceData] = useState<HivebriteSource | null>(null);
  const [isLoadingSourceData, setIsLoadingSourceData] = useState<boolean>(false);

  useEffect(() => {
    setAreImportsLoading(true);

    getExistingImports({ audienceId })
      .then((res) => {
        setImports(res.imports);
      })
      .finally(() => setAreImportsLoading(false));
  }, []);

  useEffect(() => {
    if (selectedImport) {
      setIsLoadingSourceData(true);

      const importInfo = imports.find(({ id }) => id.toString() === selectedImport);

      getSourceInformation({
        id: selectedImport,
        type: importInfo.type,
      })
        .then(setSourceData)
        .finally(() => setIsLoadingSourceData(false));
    }
  }, [selectedImport]);

  if (areImportsLoading) {
    return (
      <div>
        <Skeleton widthPercentage={30} />

        <Skeleton widthPercentage={55} />
      </div>
    );
  }

  const importRadios: Pick<RadioProps, 'description' | 'label' | 'value'>[] = imports.map(({
    lastImportedAt, title, type, id,
  }) => ({
    description: `${SEGMENT_LABELS[type]} - Last imported ${formatDate(lastImportedAt)}`,
    label: title,
    value: id.toString(),
  }));

  const handleOnChange = (value: string) => {
    setSelectedImport(value);
  };

  const handleOnSubmit: OnSubmit = async () => {
    const { type } = imports.find(({ id }) => id.toString() === selectedImport);

    await importHivebriteMembers({
      audienceId,
      id: selectedImport,
      type,
    });

    onSubmit();
  };

  return (
    <Form name="hivebrite-import" onSubmit={handleOnSubmit}>
      <div className="border-b-gray-300 border-b border-solid pb-4 max-h-[300px] overflow-y-auto">
        <Radios
          name="existing-import-id"
          onChange={handleOnChange}
          options={importRadios}
          value={selectedImport}
        />
      </div>

      {isLoadingSourceData
        ? (
          <div className="mt-3">
            <Skeleton widthPercentage={25} />

            <Skeleton />
          </div>
        )
        : null}

      {
        sourceData && !isLoadingSourceData
          ? <HivebriteSourceInformation numberOfMembers={sourceData.numberOfMembers} />
          : null
      }

      <SubmitButton
        appearance={Button.APPEARANCES.AFFIRMING}
        className="mt-3"
        disabled={!selectedImport}
        isSubmitting={isImportingMembers}
        type="submit"
      >Add{`${sourceData ? ` ${sourceData.numberOfMembers} ` : ' '}`}members
      </SubmitButton>
    </Form>
  );
};


HivebriteExistingImportsForm.displayName = 'HivebriteExistingImportsForm';

export { HivebriteExistingImportsForm };
