export const INITIALISE_ENGAGEMENT_TEMPLATE = `
mutation InitialiseEngagementTemplate(
  $areasOfInterest: [EngagementQuestionOptionInput]!
  $chaserEmail: CreateEmailInput!
  $chaserEmailType: EmailType!
  $conversationGuideId: ID!
  $conversationGuide: ConversationGuideInput!
  $description: String!
  $engagementId: ID!
  $inviteEmail: CreateEmailInput!
  $inviteEmailType: EmailType!
  $title: String!
  $workspaceId: ID!
) {
  updateConversationGuide(
    conversationGuideId: $conversationGuideId,
    conversationGuide: $conversationGuide
  ) {
    id
  }

  initialiseOptInQuestions(
    areasOfInterest: $areasOfInterest
    engagementId: $engagementId
    topicDescription: $description
    topicTitle: $title
    workspaceId: $workspaceId
  ) {
    id
  }

  inviteEmail: createEmail(
    engagementId: $engagementId
    emailType: $inviteEmailType
    email: $inviteEmail
  ) {
    id
    type
  }

  chaserEmail: createEmail(
    engagementId: $engagementId
    emailType: $chaserEmailType
    email: $chaserEmail
  ) {
    id
    type
  }
}
`;
