import { Navigate } from 'react-router-dom';

import { RouterErrorBoundary } from './ErrorBoundary/RouterErrorBoundary.tsx';

import './vendor-component-decorations.mjs';


const index = true;

/* eslint-disable sort-keys */

const audiences = {
  path: 'audiences',
  children: [
    {
      index,
      lazy: () => import('./w/workspace/audiences/AudiencesListPage.tsx'),
    },
    {
      path: ':audienceId',
      lazy: () => import('./w/workspace/audiences/audience/AudiencePage.tsx'),
    },
  ],
};

const engagements = {
  path: 'engagements',
  children: [
    {
      index,
      lazy: () => import('./w/workspace/engagements/EngagementsListPage.tsx'),
    },
    {
      path: ':engagementId',
      lazy: () => import('…/app/w/workspace/engagements/engagement/EngagementContainer.tsx'),
      children: [
        {
          path: 'dashboard',
          lazy: () => import('…/app/deprecated/components/engagement/Dashboard/index.jsx'),
        },
        {
          path: 'dashboard-emails',
          lazy: () => import('…/app/w/workspace/engagements/engagement/Dashboard/EmailStatisticsPage.tsx'),
        },
        {
          path: 'feedback',
          lazy: () => import('…/app/w/workspace/engagements/engagement/FeedbackForms/FeedbackFormsPage.tsx'),
        },
        {
          path: 'matches',
          lazy: () => import('…/app/w/workspace/engagements/engagement/Matches/EditMatchesSFC.tsx'),
        },
        {
          path: 'optins',
          lazy: () => import('…/app/w/workspace/engagements/engagement/OptIns/OptInFormsPage.tsx'),
        },
        {
          path: 'setup',
          children: [
            {
              index,
              lazy: () => import('…/app/w/workspace/engagements/engagement/setup/overview/Overview.tsx'),
            },
            {
              path: 'conversation-guide',
              lazy: () => import('…/app/w/workspace/engagements/engagement/setup/emails/EngagementConversationGuidePage.tsx'),
            },
            {
              path: 'feedback',
              lazy: async () => {
                const { Component, ...rest } = await import('…/app/w/workspace/engagements/engagement/setup/participant-forms/ParticipantFormSetupPage.tsx');
                return {
                  ...rest,
                  element: <Component key="feedback" />,
                };
              },
              handle: {
                title: 'Engagement Setup Feedback',
              },
            },
            {
              path: 'invite',
              lazy: async () => {
                const { Component, ...rest } = await import('…/app/w/workspace/engagements/engagement/setup/emails/EngagementEmailSetupPage.tsx');
                return {
                  ...rest,
                  element: <Component key="invite" />,
                };
              },
              handle: {
                title: 'Engagement Setup Invitation',
              },
            },
            {
              path: 'chaser',
              lazy: async () => {
                const { Component, ...rest } = await import('…/app/w/workspace/engagements/engagement/setup/emails/EngagementEmailSetupPage.tsx');
                return {
                  ...rest,
                  element: <Component key="chaser" />,
                };
              },
              handle: {
                title: 'Engagement Setup Reminder',
              },
            },
            {
              path: 'schedule',
              lazy: () => import('…/app/w/workspace/engagements/engagement/setup/schedule/EngagementSchedulePage.tsx'),
            },
            {
              path: 'signup',
              lazy: async () => {
                const { Component, ...rest } = await import('…/app/w/workspace/engagements/engagement/setup/participant-forms/ParticipantFormSetupPage.tsx');
                return {
                  ...rest,
                  element: <Component key="signup" />,
                };
              },
              handle: {
                title: 'Engagement Setup Optin',
              },
            },
            {
              path: 'template',
              lazy: () => import('…/app/w/workspace/engagements/engagement/setup/template/EngagementTemplateSetupPage.tsx'),
            },
          ],
        },
        {
          path: 'settings',
          lazy: () => import('…/app/w/workspace/engagements/engagement/Settings/EngagementSettingsPage.tsx'),
        },
      ],
    },
  ],
};

const walled = {
  path: '/w',
  lazy: () => import('./w/AuthWall.tsx'),
  children: [
    {
      index,
      lazy: () => import('./w/WorkspacesListPage.jsx'),
    },
    {
      path: ':workspaceId',
      lazy: () => import('./w/WorkspaceWrapper.jsx'),
      children: [
        {
          lazy: () => import('./w/workspace/HomePage.jsx'),
          path: 'home-prototype',
        },
        {
          index,
          element: <Navigate to="engagements" />,
        },
        {
          path: 'settings',
          lazy: () => import('./w/workspace/WorkspaceSettings/WorkspaceSettingsContainer.tsx'),
          children: [
            {
              index,
              element: <Navigate to="profile" />,
            },
            {
              path: 'brand',
              lazy: () => import('./w/workspace/WorkspaceSettings/Brand/WorkspaceBranding.tsx'),
            },
            {
              path: 'integrations',
              lazy: () => import('./w/workspace/WorkspaceSettings/Integrations/WorkspaceIntegrationsPage.jsx'),
            },
            {
              path: 'profile',
              lazy: () => import('./w/workspace/WorkspaceSettings/UserProfile/UserProfile.tsx'),
            },
            {
              path: 'team',
              lazy: () => import('./w/workspace/WorkspaceSettings/Team/WorkspaceTeam.tsx'),
            },
            {
              path: 'whitelabeling',
              lazy: () => import('./w/workspace/WorkspaceSettings/Whitelabeling/WorkspaceWhitelabelingPage.jsx'),
            },
          ],
        },
        audiences,
        engagements,
        {
          lazy: () => import('./w/workspace/analytics/Dashboard.jsx'),
          path: 'analytics',
        },
      ],
    },
  ],
};

export const routes = [
  {
    path: '*', // React-Router v6 will only choose this route when there's no more-specific match
    lazy: () => import('./404.tsx'),
  },
  {
    lazy: () => import('./App.tsx'),
    ErrorBoundary: RouterErrorBoundary,
    children: [
      {
        path: '/',
        lazy: () => import('./LandingPage.tsx'),
      },
      {
        path: '/logout',
        lazy: () => import('./Logout.tsx'),
      },
      {
        path: '/sandbox',
        lazy: () => import('./SandboxPortal/SandboxPage.tsx'),
      },
      {
        path: '/welcome/:token',
        lazy: () => import('./Welcome.tsx'),
      },
      walled,
    ],
  },
];
/* eslint-enable sort-keys */
