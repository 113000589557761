._Skeleton_4nyox_1 {
  animation: 1.5s _shine_4nyox_1 linear infinite;
  background: #f9f9f9;
  background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
  background-size: 200% 100%;
  border-radius: 5px;
  height: 20px;
  margin-bottom: 8px;
}

@keyframes _shine_4nyox_1 {
  to {
    background-position-x: -200%;
  }
}
