import {IIcon} from '…/app/w/workspace/common/Icon/Icon.js';

const HivebriteImportFormTitle = () => (
  <h1 className="flex items-center">
    <IIcon
      className="relative bottom-0.5 mr-2"
      label="Hivebrite logo"
      name="hivebrite"
    />
    Import members from Hivebrite
  </h1>
);

HivebriteImportFormTitle.displayName = 'HivebriteImportFormTitle';

export { HivebriteImportFormTitle };
