import { gqlOperation } from '…/app/common/rpc/gqlOperation.mts';

import ADD_WHITELABELING_DOMAIN from './addWhitelabelingDomain.gql';

export function addWhitelabelingDomain({
  rootDomain,
  workspaceId,
}: {
  rootDomain: string;
  workspaceId: string;
}) {
  return gqlOperation(ADD_WHITELABELING_DOMAIN, {
    rootDomain,
    workspaceId,
  });
}
