import { clsx } from 'clsx';

import Button from 'form5/react/Button';
import { CTA } from '…/app/w/workspace/common/CTA/CTA.tsx';

import aiPowered from './vectors/AI-powered.svg';
import alarmClock from './vectors/alarm-clock.svg';
import arrowCircle from './vectors/arrow-circle.svg';
import barChart from './vectors/bar-chart.svg';
import builtin from './vectors/builtin.svg';
import check from './vectors/check.svg';
import copy from './vectors/copy.svg';
import cuppa from './vectors/cuppa.svg';
import customAudience from './vectors/custom-audience.svg';
import diamond from './vectors/diamond.svg';
import document from './vectors/document.svg';
import dotsVertical from './vectors/dots-vertical.svg';
import doubleChevronLeft from './vectors/double-chevron-left.svg';
import doubleChevronRight from './vectors/double-chevron-right.svg';
import engagementDashboard from './vectors/engagement-dashboard.svg';
import engagementEmailDashboard from './vectors/engagement-email-dashboard.svg';
import engagementFeedback from './vectors/engagement-feedback.svg';
import engagementMatching from './vectors/engagement-matching.svg';
import engagementOptIns from './vectors/engagement-optins.svg';
import engagementSetttings from './vectors/engagement-settings.svg';
import engagementSetup from './vectors/engagement-setup.svg';
import envelope from './vectors/envelope.svg';
import facebook from './vectors/facebook.svg';
import funnel from './vectors/funnel.svg';
import gear from './vectors/gear.svg';
import github from './vectors/github.svg';
import group from './vectors/group.svg';
import handshake from './vectors/handshake.svg';
import hivebrite from './vectors/hivebrite.svg';
import hubspot from './vectors/hubspot.svg';
import info from './vectors/info.svg';
import instagram from './vectors/instagram.svg';
import lightning from './vectors/lightning.svg';
import linkedin from './vectors/linkedin.svg';
import match from './vectors/match.svg';
import medium from './vectors/medium.svg';
import mute from './vectors/mute.svg';
import notEditable from './vectors/not-editable.svg';
import padlock from './vectors/padlock.svg';
import patreon from './vectors/patreon.svg';
import pencil from './vectors/pencil.svg';
import people from './vectors/people.svg';
import pinterest from './vectors/pinterest.svg';
import rings from './vectors/rings.svg';
import salesforce from './vectors/salesforce.svg';
import settings from './vectors/settings.svg';
import starFilled from './vectors/star-filled.svg';
import starsTwinkle from './vectors/stars-twinkle.svg';
import success from './vectors/success.svg';
import sync from './vectors/sync.svg';
import tiktok from './vectors/tiktok.svg';
import trash from './vectors/trash.svg';
import twitter from './vectors/twitter.svg';
import unlink from './vectors/unlink.svg';
import url from './vectors/url.svg';
import youtube from './vectors/youtube.svg';

import classes from './Icon.module.css';


const icons = {
  'AI-powered': aiPowered,
  'alarm clock': alarmClock,
  'bar chart': barChart,
  builtin,
  check,
  copy,
  cuppa,
  'custom audience': customAudience,
  diamond,
  document,
  'dots vertical': dotsVertical,
  'engagement dashboard': engagementDashboard,
  'engagement email dashboard': engagementEmailDashboard,
  'engagement feedback': engagementFeedback,
  'engagement matching': engagementMatching,
  'engagement optins': engagementOptIns,
  'engagement settings': engagementSetttings,
  'engagement setup': engagementSetup,
  envelope,
  facebook,
  'filter': funnel,
  funnel,
  gear,
  github,
  group,
  handshake,
  hivebrite,
  hubspot,
  info,
  instagram,
  linkedin,
  match,
  medium,
  'menu close': doubleChevronLeft,
  'menu open': doubleChevronRight,
  mute,
  'not editable': notEditable,
  padlock,
  patreon,
  pencil,
  pinterest,
  rings,
  salesforce,
  settings,
  'star filled': starFilled,
  success,
  sync,
  tiktok,
  trash,
  twitter,
  unlink,
  url,
  'user new': starsTwinkle,
  'user power': lightning,
  'user referral': people,
  'user repeat': arrowCircle,
  'user super': lightning,
  youtube,
};

export function socialIconMap(socialUrl: string) {
  if (!socialUrl) return null;

  if (socialUrl.includes('linkedin.')) {
    return 'linkedin';
  }
  if (socialUrl.includes('instagram.')) {
    return 'instagram';
  }
  if (socialUrl.includes('tiktok.')) {
    return 'tiktok';
  }
  if (socialUrl.includes('twitter.')) {
    return 'twitter';
  }
  if (socialUrl.includes('facebook.')) {
    return 'facebook';
  }
  if (socialUrl.includes('youtube.')) {
    return 'youtube';
  }
  if (socialUrl.includes('github.')) {
    return 'github';
  }
  if (socialUrl.includes('pinterest.')) {
    return 'pinterest';
  }
  if (socialUrl.includes('medium.')) {
    return 'medium';
  }
  if (socialUrl.includes('patreon.')) {
    return 'patreon';
  }

  return null;
}

export function IIcon({
  className,
  fill = 'currentColor',
  label = null,
  name,
}: IIconProps) {
  const Icon = icons[name];

  if (!Icon) return null;

  return (
    <Icon
      aria-label={label}
      className={clsx(className, classes.IIcon)}
      fill={fill}
    />
  );
}
IIcon.displayName = 'IIcon';

export interface IIconProps {
  className?: string,
  fill?: CSSStyleDeclaration['fill'],
  label: string | null,
  name: keyof typeof icons,
}

export function SocialIconLink({ url: socialUrl }: { url: string }) {
  const label = socialIconMap(socialUrl) || 'url';
  return (
    <CTA
      appearance={Button.APPEARANCES.AFFIRMING}
      className="icon"
      guise={CTA.GUISES.BUTTON}
      rel="noreferrer"
      target="_blank"
      to={socialUrl}
      variant={CTA.VARIANTS.GLYPH}
    >
      <IIcon label={label} name={label} />
    </CTA>
  );
}

SocialIconLink.displayName = 'SocialIconLink';
