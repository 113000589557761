import { gqlOperation } from '…/app/common/rpc/gqlOperation.mts';

import SEND_EMAIL_PREVIEW from './sendEmailPreview.gql';


export function sendEmailPreview({
  engagementId,
  type,
  workspaceId,
}: {
  engagementId: string,
  type: 'chaser' | 'invite',
  workspaceId: string,
}) {
  return gqlOperation(SEND_EMAIL_PREVIEW, {
    engagementId,
    type,
    workspaceId,
  })
    .then((result: { sent?: boolean }) => !!result?.sent);
}
