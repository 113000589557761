._Content_5hg4l_1 {
  display: flex;
  flex: 3;
  flex-direction: column;
}

._SectionSplit_5hg4l_7 {
  display: flex;
  flex: 1;
  flex-direction: column;
}

._Instructions_5hg4l_13 {
  align-items: left;
  flex: 2;
}

._Instructions_5hg4l_13 h3,
._Instructions_5hg4l_13 p {
  margin-bottom: 1rem;
}