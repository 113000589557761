import classes from './Skeleton.module.css';

interface SkeletonProps {
  widthPercentage?: number;
}

const Skeleton = ({ widthPercentage = 100 }: SkeletonProps)=>
  <div className={classes.Skeleton} style={{width: `${widthPercentage}%`}} />;

Skeleton.displayName = 'Skeleton';

export { Skeleton };
