import { HivebriteSegmentType } from '…/app/w/workspace/audiences/audience/members/hivebrite/types.js';

interface GetExistingImportsProps {
  audienceId: string | number;
}

export interface ExistingImport {
  id: string | number;
  lastImportedAt: string;
  title: string;
  type: HivebriteSegmentType;
}

export interface ExistingImports {
  audienceId: string | number;
  imports: ExistingImport[];
}

const getExistingImports = ({ audienceId }: GetExistingImportsProps): Promise<ExistingImports> => {
  console.log('getExistingImports', audienceId);

  if (!audienceId) {
    return Promise.reject({error: 'audienceId is missing'});
  }

  return new Promise((resolve) => {
    setTimeout(() => {
      resolve({
        audienceId,
        imports: [
          {
            id: 10001,
            lastImportedAt: '2025-02-24T13:06:53Z',
            title: 'LUUK - Group 1',
            type: 'group',
          },
          {
            id: 10002,
            lastImportedAt: '2025-02-23T17:06:53Z',
            title: 'LUUK - Event 7',
            type: 'event',
          },
          {
            id: 10003,
            lastImportedAt: '2025-02-22T08:06:53Z',
            title: 'LUUK - Cluster 42',
            type: 'cluster',
          },
          {
            id: 10011,
            lastImportedAt: '2025-02-21T23:06:53Z',
            title: 'LUUK - Group 132',
            type: 'group',
          },
          {
            id: 10022,
            lastImportedAt: '2025-02-20T17:06:53Z',
            title: 'LUUK - Event 79',
            type: 'event',
          },
          {
            id: 10033,
            lastImportedAt: '2025-02-19T08:06:53Z',
            title: 'LUUK - Cluster 42',
            type: 'cluster',
          },
          {
            id: 10043,
            lastImportedAt: '2025-02-22T08:06:53Z',
            title: 'LUUK - Cluster 42',
            type: 'cluster',
          },
          {
            id: 10041,
            lastImportedAt: '2025-02-21T23:06:53Z',
            title: 'LUUK - Group 132',
            type: 'group',
          },
          {
            id: 10042,
            lastImportedAt: '2025-02-20T17:06:53Z',
            title: 'LUUK - Event 79',
            type: 'event',
          },
          {
            id: 10053,
            lastImportedAt: '2025-02-19T08:06:53Z',
            title: 'LUUK - Cluster 42',
            type: 'cluster',
          },
        ],
      });
    }, 1);
  });
};

export { getExistingImports };
