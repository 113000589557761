export const GET_ENGAGEMENT_OPTINS = `
query GetEngagementOptIns(
  $after: String
  $before: String
  $engagementId: ID!
  $filters: [OptInFormsFilter]
  $first: Int
  $last: Int
  $search: String
  $sort: OptInFormsSorting
  $workspaceId: ID!
) {
  workspace(workspaceId: $workspaceId) {
    engagement(id: $engagementId) {
      optInForms(
        after: $after
        before: $before
        filters: $filters
        first: $first
        last: $last
        search: $search
        sort: $sort
      ) {
        nodes {
          id
          answers {
            stepName
            selectedOptions
            text
          }
          createdAt
          fields {
            email
            fullName
            role
            company
            timezoneIso
            timeslotsUtc
          }
          numPreviousOptinForms
          referral {
            id
            referredByMember {
              email
              fields {
                fullName
              }
            }
          }
        }
        pageInfo {
          hasNextPage
          hasPreviousPage
          startCursor
          endCursor
        }
        totalCount
      }
    }
  }
}`;
