import Button from 'form5/react/Button';
import Field from 'form5/react/Field';
import Form, { type OnSubmit } from 'form5/react/Form';
import {
  type Dispatch,
  type SetStateAction,
  useState,
} from 'react';
import { toast } from 'sonner';

import { SubmitButton } from '…/app/common/SubmitButton/SubmitButton.jsx';

import {
  adjustTimezoneToUTC,
  adjustUTCtoTimezone,
  adjustUTCtoTimezoneISO,
  getNow,
} from '…/app/common/dateUtils.mts';

import type { Engagement, EngagementsDict } from '../Engagement.d.ts';
import { updateEngagement } from './gql/updateEngagement.op.mts';

import classes from './RecurEngagementForm.module.css';

export function RecurEngagementForm(
  {
    engagement: {
      interval,
      isAutoscheduling,
      isRecurringEnabled,
      optInClosesAt,
      repeat,
      seriesEndDate,
      timezoneIso,
    },
    engagementId,
    setEngagements,
    setOpen,
    workspaceId,
  }: {
    engagement: Engagement,
    engagementId: string,
    setEngagements: Dispatch<SetStateAction<EngagementsDict>>,
    setOpen: Dispatch<SetStateAction<boolean>>,
    workspaceId: string,
  },
) {
  const [isSaving, setIsSaving] = useState(false);

  const nowBrowserLocale = getNow();
  const editable = !optInClosesAt || adjustUTCtoTimezone(optInClosesAt, timezoneIso) > nowBrowserLocale;
  const optInClosesAtDate = adjustUTCtoTimezone(optInClosesAt, timezoneIso);
  const datePickerMinDate = (
    optInClosesAt
      ? optInClosesAtDate.plus({ weeks: 1 })
      : nowBrowserLocale.plus({ weeks: 1 })
        .startOf('day')
  ).toISODate();
  const datePickerMaxDate = nowBrowserLocale
    .plus({
      weeks: 1,
      years: 2,
    })
    .endOf('week')
    .toISODate();

  const onSubmit: OnSubmit = (_, all) => {
    setIsSaving(true);

    const req = updateEngagement({
      engagement: {
        ...all,
        seriesEndDate: `${adjustTimezoneToUTC(all.seriesEndDate, timezoneIso)}Z`,
      },
      engagementId,
      workspaceId,
    })
      .then((result: Engagement) => {
        setEngagements((prev) => {
          const updated = new Map(prev);
          updated.set(result.id, result);
          return updated;
        });

        setOpen(false);
      })
      .catch((err: AggregateError | Error) => toast.error(err?.errors?.[0].message || err.message))
      .finally(() => setIsSaving(false));

    toast.promise(req, {
      error: 'Saving recurrence failed.',
      loading: 'Saving recurrence',
      success: 'Recurrence saved.',
    });

    return req;
  };

  return (
    <Form
      name="recur-engagement"
      onSubmit={onSubmit}
    >
      <fieldset disabled={!editable}>
        <header className="SplitHeader">
          <h2>Recurring Engagement</h2>

          <Field
            defaultChecked={isRecurringEnabled ?? true}
            label={null}
            name="isRecurringEnabled"
            type="checkbox"
            variant={Field.VARIANTS.TOGGLE}
          />
        </header>

        <div className="SplitContent">
          <Field
            arrangement={Field.ARRANGEMENTS.STACKED}
            defaultValue={interval ?? 1}
            label="Occurs"
            max={99}
            min={1}
            name="interval"
            required
            type="number"
          />

          <Field
            arrangement={Field.ARRANGEMENTS.STACKED}
            as="select"
            defaultValue={repeat?.toUpperCase() || 'WEEK'}
            label="Times per"
            name="repeat"
            required
          >
            <option value="WEEK">week(s)</option>

            <option value="MONTH">month(s)</option>
          </Field>

          <Field
            arrangement={Field.ARRANGEMENTS.STACKED}
            defaultValue={adjustUTCtoTimezoneISO(
              seriesEndDate,
              timezoneIso,
              'date',
            )}
            label="Stops recurring after"
            max={datePickerMaxDate}
            min={datePickerMinDate}
            name="seriesEndDate"
            required
            type="date"
          />
        </div>

        <div className={classes.AutoPilotSection}>
          <Field
            defaultChecked={isAutoscheduling ?? false}
            label="Auto-Pilot"
            name="isAutoscheduling"
            type="checkbox"
            variant={Field.VARIANTS.TOGGLE}
          />

          <p className={classes.Paragraph}>
            Auto-pilot automatically schedule the next engagement when the current one ends.
            You can always unschedule the engagement and make changes.
          </p>
        </div>

        <SubmitButton
          appearance={Button.APPEARANCES.PRIMARY}
          disabled={isSaving}
          isSubmitting={isSaving}
          type="submit"
        >Save
        </SubmitButton>
      </fieldset>
    </Form>
  );
}
RecurEngagementForm.displayName = 'RecurEngagementForm';
