const analyticsMetrics = {
  // Metrics bundle feature flags.
  analyticsMetricsAdvanced: 'ANALYTICS_METRICS_ADVANCED',
  analyticsMetricsBase: 'ANALYTICS_METRICS_BASE',
  analyticsMetricsCore: 'ANALYTICS_METRICS_CORE',
  analyticsMetricsPremium: 'ANALYTICS_METRICS_PREMIUM',
  analyticsMetricsQuestions: 'ANALYTICS_METRICS_QUESTIONS',
};
const core = {
  archiveOptInForm: 'ARCHIVE_OPTIN_FORM',
  cloneEngagement: 'CLONE_ENGAGEMENT',
  engagementSelfScheduling: 'ENGAGEMENT_SELF_SCHEDULING',
  matchEditing: 'MATCH_EDITING',
  memberMatchView: 'MEMBER_MATCH_VIEW',
  memberProfile: 'MEMBER_PROFILE',
  navigationResources: 'CORE_RESOURCES_NAVIGATION',
  optInFormLink: 'CORE_OPTIN_FORM_LINK',
  optInFormTestLink: 'CORE_OPTIN_FORM_TEST_LINK',
  optInRowData: 'OPT_IN_ROW_DATA',
  recurringEngagements: 'RECURRING_ENGAGEMENTS',
  recurringEngagementsLink: 'RECURRING_ENGAGEMENTS_LINK',
};
const csvDownloads = {
  downloadFeedbackCSV: 'DOWNLOAD_FEEDBACK_CSV',
  downloadNoChatCSV: 'DOWNLOAD_NOCHAT_CSV',
  downloadOptInBreakdownCSV: 'DOWNLOAD_OPTIN_BREAKDOWN_CSV',
};
const customFields = {
  workspaceCustomFields: 'WORKSPACE_CUSTOM_FIELDS',
};
const dashboards = {
  dashboardAnalytics: 'ANALYTICS_DASHBOARD',
};
const dev = {
  logout: 'LOGOUT_DEV',
};
const feedbackForms = {
  customFeedbackQuestion: 'CORE_CUSTOM_FEEDBACK_QUESTION',
  customFeedbackQuestionX4: 'CUSTOM_FEEDBACK_QUESTION_X4',
  editFeedbackQuestions: 'EDIT_FEEDBACK_FORM',
  // customFeedbackQuestionX10: 'CUSTOM_FEEDBACK_QUESTION_X10', // TODO: requires API validation to be removed.
};
const filtering = {
  engagementFilters: 'ENGAGEMENT_FILTERS',
  optInFilters: 'OPTIN_FILTERS',
};
const optinFormQuestions = {
  customQuestion: 'CORE_CUSTOM_SINGLE_SELECT_MATCHING_QUESTION',
  customQuestionX4: 'CUSTOM_MATCHING_QUESTION_X4',
  // customQuestionX10: 'CUSTOM_MATCHING_QUESTION_X10', // TODO: requires API validation to be removed.
};
const productTypes = {
  createEngagementGroup: 'CREATE_GROUP_MATCH_ENGAGEMENT',
  createEngagementTopN: 'CREATE_TOPN_MATCH_ENGAGEMENT',
};
const questionOptions = {
  customMultiSelectQuestion: 'CUSTOM_MULTI_SELECT_QUESTION',
  disableQuestionForMatching: 'DISABLE_QUESTION_FOR_MATCHING',
  matchingOppositesQuestion: 'MATCHING_OPPOSITES_QUESTION',
};

const matchEditing = {
  matchMeta: 'MATCH_META_INFO',
};

const smartFeatures = {
  emailRecentFeedback: 'EMAIL_RECENT_FEEDBACK',
};

const integrationsFeatures = {
  workspaceIntegrations: 'WORKSPACE_INTEGRATIONS',
  workspacePublicAPI: 'WORKSPACE_PUBLIC_API',
};

export const FLAGS = {
  addAlternativeMemberEmails: 'ADD_ALTERNATIVE_MEMBER_EMAILS',
  uploadWorkspaceLogo: 'BRANDING_UPLOAD_WORKSPACE_LOGO',
  ...analyticsMetrics,
  ...core,
  ...csvDownloads,
  ...customFields,
  ...dashboards,
  ...dev,
  ...feedbackForms,
  ...filtering,
  ...integrationsFeatures,
  ...matchEditing,
  ...optinFormQuestions,
  ...productTypes,
  ...questionOptions,
  ...smartFeatures,
};

export type FLAGS = typeof FLAGS[keyof typeof FLAGS];
