const freeResponseQuestionFields = `
  props {
    ledeIn
    title
  }
`;
const customQuestionFields = `
  isDataPrivate
  isOrdered
  isMatchingOpposites
  isMatchingDisabled
  question
`;
const multipleChoiceQuestionFields = `
  props {
    ledeIn
    options {
      id
      text
    }
    title
    variableConfig
  }
`;
const typeDependentQuestionFields = `
  ... on EngagementQuestionGenericSingleSelectStep {
    ${customQuestionFields}
    ${multipleChoiceQuestionFields}
  }
  ... on EngagementQuestionGenericMultiSelectStep {
    ${customQuestionFields}
    ${multipleChoiceQuestionFields}
  }
  ... on EngagementQuestionGenericTextStep {
    ${customQuestionFields}
    ${freeResponseQuestionFields}
  }
`;

export const typeAudienceLight = `
  audience {
    id
    name
  }
`;

export const typeEngagementStats = `
  engagementStats {
    avgMatchRating
    nps
    numFeedbackForms
    numOptinForms
  }
`;

export const typeEngagementRecurrence = `
  recurrence {
    interval
    isAutoscheduling
    isRecurringEnabled
    repeat
    seriesEndDate
    seriesId
  }
`;

const emailFields = `
  id
  bodyJson
  callToAction
  isDisabled
  preheader
  sendAt
  subject
  type
`;

const typeCalculatedStats = `
  matchesCount
  matchRating { rating }
  optInFormsCount
`;

const typeSettings = `
  batchSize
  disableCompanyBlock
  isAutoExecute
  sendNoshowEmail
  sendNotMatchedEmail
`;


export default `
fragment engagementFields on Engagement {
  id
  __typename
  audienceId
  ${typeAudienceLight}
  ${typeEngagementStats}
  isEditMatchesEnabled
  isLive
  isInReview
  audienceId
  conversationDates
  conversationGuide {
    id
    isDisabled
    json
  }
  createdAt
  cancelledAt
  description
  durationMinutes
  emails {
    ${emailFields}
  }
  emailsSentFromAddress
  groupSize
  matchIntroAt
  ${typeCalculatedStats}
  name
  optInClosesAt
  optInOpensAt
  product
  scheduledAt
  slug
  status
  timezoneIso
  title
  feedbackQuestions {
    __typename
    id
    isDisabled
    lockedProps
    question
    stepName
    ... on EngagementQuestionGenericRatingStep {
      ${freeResponseQuestionFields}
    }
    ... on EngagementQuestionSpecificNPSStep {
      ${freeResponseQuestionFields}
    }
    ${typeDependentQuestionFields}
  }
  optInQuestions {
    __typename
    id
    isDisabled
    lockedProps
    stepName
    ... on EngagementQuestionGenericUserStep {
      ${freeResponseQuestionFields}
    }
    ... on EngagementQuestionSpecificProfessionalStep {
      ${freeResponseQuestionFields}
    }
    ... on EngagementQuestionSocialStep {
      ${freeResponseQuestionFields}
      props {
        variableConfig
      }
    }
    ... on EngagementQuestionSpecificPhoneStep {
      ${freeResponseQuestionFields}
    }
    ${typeDependentQuestionFields}
  }
  ${typeEngagementRecurrence}
  ${typeSettings}
}
`;
