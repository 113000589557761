const SEGMENT_TYPE = Object.freeze({
  cluster: 'cluster',
  event: 'event',
  group: 'group',
});

const SEGMENT_LABELS = {
  [SEGMENT_TYPE.cluster]: 'Cluster',
  [SEGMENT_TYPE.event]: 'Event',
  [SEGMENT_TYPE.group]: 'Group',
};

export { SEGMENT_TYPE, SEGMENT_LABELS };
