import {useTabs} from '…/app/common/Tabs/useTabs.js';
import {
  SimpleTab, SimpleTabs,
} from '…/app/common/Tabs/SimpleTabs.js';
import {HivebriteImportForm} from '…/app/w/workspace/audiences/audience/members/hivebrite/HivebriteImportForm.js';
import {
  HivebriteExistingImportsForm,
} from '…/app/w/workspace/audiences/audience/members/hivebrite/HivebriteExistingImportsForm.js';

const HivebriteImportFormPicker = ({onSubmit}: {onSubmit: () => void}) => {
  const tabs: SimpleTab[] = [
    {
      id: 'new-import',
      label: 'New import',
    },
    {
      id: 'existing-import',
      label: 'Existing import',
    },
  ];
  const { activeTab, setActiveTab } = useTabs({tabs: tabs.map(({id}) => id)});

  return (
    <>
      <SimpleTabs
        activeTab={activeTab}
        onClick={setActiveTab}
        tabs={tabs}
      />

      {activeTab === 'new-import' ? <HivebriteImportForm noTitle onSubmit={onSubmit} /> : null}

      {activeTab === 'existing-import' ? <HivebriteExistingImportsForm onSubmit={onSubmit} /> : null}
    </>
  );
};

HivebriteImportFormPicker.displayName = 'HivebriteImportFormPicker';

export {HivebriteImportFormPicker};
