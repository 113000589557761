query GetEngagements(
  $workspaceId: ID!,
  $after: String,
  $first: Int,
  $before: String,
  $last: Int,
  $search: String,
  $sort: EngagementsSorting,
  $filters: [EngagementsFilter]
) {
  workspace(workspaceId: $workspaceId) {
    engagements(
      first: $first,
      last: $last,
      search: $search,
      after: $after,
      before: $before,
      sort: $sort,
      filters: $filters
    ) {
      nodes {
        audience {
          id
          name
        }
        description
        engagementStats {
          avgMatchRating
          nps
          numFeedbackForms
          numOptinForms
        }
        groupSize
        id
        isLive
        matchesCount
        name
        optInClosesAt
        optInFormsCount
        optInOpensAt
        product
        slug
        status
        timezoneIso
        ... on EngagementRecurring {
          interval
          isAutoscheduling
          isRecurringEnabled
          repeat
          seriesEndDate
          seriesId
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      totalCount
    }
    id
  }
}
