import {useEffect, useState} from 'react';
import Form, { type OnSubmit } from 'form5/react/Form';
import Button from 'form5/react/Button';

import {SubmitButton} from '…/app/common/SubmitButton/SubmitButton.jsx';
import {Combobox} from '…/app/common/Combobox/Combobox.tsx';
import {SEGMENT_TYPE} from '…/app/w/workspace/audiences/audience/members/hivebrite/constants.js';
import {
  getHivebriteSegment,
} from '…/app/w/workspace/audiences/audience/members/hivebrite/queries/getHivebriteSegment.op.mjs';
import {
  HivebriteSegmentOption,
  HivebriteSegmentSource,
  HivebriteSource,
} from '…/app/w/workspace/audiences/audience/members/hivebrite/types.js';
import {
  getSourceInformation,
} from '…/app/w/workspace/audiences/audience/members/hivebrite/queries/getSourceInformation.op.mjs';
import {
  importHivebriteMembers,
} from '…/app/w/workspace/audiences/audience/members/hivebrite/queries/importMembers.op.mjs';
import type {GroupBase, OptionsOrGroups} from 'react-select';
import {Skeleton} from '…/app/common/Skeleton/Skeleton.js';
import {
  HivebriteImportFormTitle,
} from '…/app/w/workspace/audiences/audience/members/hivebrite/HivebriteImportFormTitle.js';
import {
  HivebriteSourceInformation,
} from '…/app/w/workspace/audiences/audience/members/hivebrite/HivebriteSourceInformation.js';
import {useParams} from 'react-router-dom';

interface HivebriteImportFormProps {
  onSubmit: () => void;
  noTitle?: boolean;
}

const HivebriteImportForm = ({ onSubmit, noTitle = false }: HivebriteImportFormProps) => {
  const { audienceId } = useParams();
  const [isImportingMembers] = useState<boolean>(false);
  const [segment, setSegment] = useState<HivebriteSegmentOption | null>(null);
  const [segmentData, setSegmentData] =
    useState<OptionsOrGroups<HivebriteSegmentSource, GroupBase<HivebriteSegmentSource>> | null>(null);
  const [isLoadingSegmentData, setIsLoadingSegmentData] = useState<boolean>(false);

  const [source, setSource] = useState<HivebriteSegmentSource | null>(null);
  const [sourceData, setSourceData] = useState<HivebriteSource | null>(null);
  const [isLoadingSourceData, setIsLoadingSourceData] = useState<boolean>(false);

  const segmentArray: HivebriteSegmentOption[] = [
    {
      label: 'Cluster members',
      value: 'cluster',
    },
    {
      label: 'Event registrants',
      value: 'event',
    },
    {
      label: 'Group members',
      value: 'group',
    },
  ];

  const segmentLabels = {
    [SEGMENT_TYPE.cluster]: 'Select a cluster',
    [SEGMENT_TYPE.event]: 'Select an upcoming event',
    [SEGMENT_TYPE.group]: 'Select a group',
  };

  const loadSegmentData = (query?: string) => {
    if (!segment) {
      return Promise.reject({error: 'No segment selected'});
    }

    return getHivebriteSegment(segment.value, query)
      .then(({results}) => results.map(({id, name}) => ({
        label: name,
        value: id,
      })))
      .then((data) => {
        setSegmentData(data);
        return data;
      });
  };

  const handleOnChangeSegment = (value: HivebriteSegmentOption, { action }: { action: string }) => {
    setSource(null);
    setSourceData(null);

    if (action === 'clear') {
      setSegment(null);
      return;
    }

    setSegment(value);
  };

  const handleOnChangeSource = (value: HivebriteSegmentSource, { action }: { action: string }) => {
    setSourceData(null);

    if (action === 'clear') {
      setSource(null);

      return;
    }

    setSource(value);
  };

  const handleOnSubmit: OnSubmit = async () => {
    await importHivebriteMembers({
      audienceId,
      id: source.value,
      type: segment.value,
    });

    onSubmit();
  };

  useEffect(() => {
    if (segment) {
      setSegmentData(null);
      setIsLoadingSegmentData(true);

      loadSegmentData()
        .then(() => setIsLoadingSegmentData(false));
    }
  }, [segment]);

  useEffect(() => {
    if (source) {
      setIsLoadingSourceData(true);

      getSourceInformation({
        id: source.value,
        type: segment.value,
      })
        .then(setSourceData)
        .finally(() => setIsLoadingSourceData(false));
    }
  }, [source]);

  return (
    <>
      {!noTitle ? <HivebriteImportFormTitle /> : null}

      <Form name="hivebrite-import" onSubmit={handleOnSubmit}>
        <Combobox
          label="1. Select an Hivebrite user segment"
          name="segment"
          // @ts-ignore
          onChange={handleOnChangeSegment}
          options={segmentArray}
          value={segment}
        />

        { segment
          ? (
            <div className="mt-3">
              <Combobox
                defaultOptions={segmentData}
                isLoading={isLoadingSegmentData}
                label={`2. ${segmentLabels[segment.value] || 'Select from the list'}`}
                name="segmentOptions"
                // @ts-ignore
                onChange={handleOnChangeSource}
                searchFn={loadSegmentData}
                value={source}
              />
            </div>
          )
          : null }

        {isLoadingSourceData
          ? (
            <div className="mt-3">
              <Skeleton widthPercentage={25} />

              <Skeleton />
            </div>
          )
          : null}

        {
          sourceData
            ? <HivebriteSourceInformation numberOfMembers={sourceData.numberOfMembers} />
            : null
        }

        <SubmitButton
          appearance={Button.APPEARANCES.AFFIRMING}
          className="mt-3"
          disabled={(!segment || !source)}
          isSubmitting={isImportingMembers}
          type="submit"
        >Add{`${sourceData ? ` ${sourceData.numberOfMembers} ` : ' '}`}members
        </SubmitButton>
      </Form>
    </>
  );
};

HivebriteImportForm.displayName = 'HivebriteImportForm';

export { HivebriteImportForm };
