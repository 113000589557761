import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { clsx } from 'clsx';

import { rpc } from '…/app/common/rpc/client.mts';
import { FLAGS } from '…/app/common/permissions/Features/FLAGS.mts';
import { useFeatureFlag } from '…/app/common/permissions/Features/useFeatureFlag.mts';

import { IIcon } from '…/app/w/workspace/common/Icon/Icon.tsx';
// import { IconButton } from '…/app/common/IconButton/IconButton.tsx';
import { formatDate } from '…/app/common/dateUtils.mts';

import classes from '../Section.module.css';
import parentClasses from '../WorkspaceIntegrationsPage.module.css';

// const MOCK_LISTS_DATA = {
//   'lists': [
//     {
//       'id': '00BDn000008b5WoMAI',
//       'name': 'Boston Prospectives',
//     },
//     {
//       'id': '00BDn00000QTpYmMAL',
//       'name': 'Churning Members',
//     },
//     {
//       'id': '00BDn00000QTpvCMAT',
//       'name': 'TGMDCU - My Outstanding Members',
//     },
//     {
//       'id': '00BDn00000QAAAAAAA',
//       'name': 'Some very interesting list',
//     },
//     {
//       'id': '00BDn00000QBBBBBBB',
//       'name': 'Great Founders and Entrepreneurs',
//     },
//     {
//       'id': '00BDn00000QCCCCCCC',
//       'name': 'Super Stars 🦄',
//     },
//     {
//       'id': '00BDn00000QDDDDDDD',
//       'name': 'Bananas and Monkeys',
//     },
//     {
//       'id': '00BDn00000QEEEEEEE',
//       'name': 'Zebras and other animals',
//     },
//   ],
// };

const {
  any,
  func,
  string,
} = PropTypes;

export function IntegrationsSection({
  className,
  integrations,
  setWidgetConfig,
  addIntegrationList,
  deleteIntegrationList,
}) {
  const isIntegrationsEnabled = useFeatureFlag([FLAGS.workspaceIntegrations]);
  const hasIntegration = integrations?.size > 0;

  const [remoteLists, setRemoteLists] = useState([]);

  const fetchIntegrationLists = async () => {
    if (hasIntegration) {
      const integrationId = Array.from(integrations.keys())[0];
      const response = await rpc.post('actions/integration-get-lists', {
        json: { integrationId },
      });
      const responseData = await response.json();
      setRemoteLists(responseData.lists);
      // // Debug
      // setRemoteLists(MOCK_LISTS_DATA.lists);
    }
  };

  useEffect(() => {
    if (isIntegrationsEnabled) {
      fetchIntegrationLists();
    }
  }, [hasIntegration]);

  return (
    <section className={clsx('Card', className)}>
      <div className={clsx(parentClasses.Content)}>
        <h2>CRM Integrations</h2>

        {!isIntegrationsEnabled && (
          <p>You currently don’t have access to use CRM Integrations.</p>
        )}

        <div className={clsx(classes.SplitContent6040, 'StackableSplit')}>
          <div className={parentClasses.SectionSplit}>
            {[
              'Hubspot',
              'Salesforce',
            ].map((accountType) => {
              const integration = Array.from(
                integrations.values()).find(item => item.accountType === accountType.toUpperCase(),
              );
              return (
                <div
                  className={clsx(
                    classes.Row,
                    hasIntegration && !integration ? classes.RowDisabled : null,
                  )}
                  key={`integration-${accountType}`}
                >
                  <div>
                    <div className={parentClasses.IntegrationName}>
                      <IIcon name={accountType.toLowerCase()} />

                      <h4>{accountType}</h4>
                    </div>

                    {integration && (
                      <span className={parentClasses.SubText}>
                        Connected: {formatDate(integration.createdAt, null, true)}
                      </span>
                    )}
                  </div>

                  {integration && (
                    <div>
                      {/*
                      <button
                        className={parentClasses.IconButton}
                        disabled
                        onClick={() => null}
                      >
                        <IIcon name="sync" />
                      </button>
                      */}

                      <button
                        className={parentClasses.IconButton}
                        disabled={!remoteLists?.length}
                        onClick={() => setWidgetConfig({
                          data: {
                            lists: remoteLists,
                            onAdd: addIntegrationList,
                            onUnlink: deleteIntegrationList,
                            settings: integration,
                          },
                          key: 'settings',
                          name: 'settings',
                          type: 'drawer',
                        })}
                      >
                        <IIcon name="settings" />
                      </button>
                    </div>
                  )}
                </div>
              );
            })}
          </div>

          <div className={clsx(parentClasses.SectionSplit, parentClasses.Instructions)}>
            <p>
              Seamlessly connect your CRM data with our new two-way integrations.{' '}

              To start, select an integration and authorize the connection.{' '}
              You will then be able to configure how data is synchronized.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

IntegrationsSection.propTypes = {
  addIntegrationList: func.isRequired,
  className: string,
  deleteIntegrationList: func.isRequired,
  integrations: any,
  setWidgetConfig: func.isRequired,
};

IntegrationsSection.displayName = 'IntegrationsSection';
