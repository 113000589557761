import {useState} from 'react';

interface UseTabsProps {
  tabs: string[];
  defaultTab?: string;
}

const useTabs = ({ tabs = [], defaultTab = null }: UseTabsProps) => {
  const [activeTab, setActiveTab] = useState<string | null>(defaultTab || tabs[0]);

  const handleTabChange = (tabId: string) => {
    if (!tabs.find((tab) => tab === tabId)) {
      return;
    }

    setActiveTab(tabId);
  };

  return {
    activeTab,
    setActiveTab: handleTabChange,
  };
};

export { useTabs };
