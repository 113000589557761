export const GET_WORKSPACE = `
query GetWorkspace($workspaceId: ID!) {
  workspace(workspaceId: $workspaceId) {
    id
    audiences(
      first: 9999999,
      filters: [
        { field: IS_ARCHIVED, values: ["false", "true"] },
      ]
    ) {
      nodes {
        id
        createdAt
        updatedAt
        isArchived
        isPrimary
        isBuiltin
        lastSyncedAt
        name
        memberCount
        integration {
          accountType
          id
        }
      }
      totalCount
    }
    features {
      name
    }
    integrations {
      id
      accountType
    }
    name
    notifications {
      identifier
      properties {
        type
        value
      }
    }
    primaryAudience
    slug
  }
}
`;
