import {
  typeAudienceLight, typeEngagementRecurrence, typeEngagementStats,
} from './engagement/gql/engagement.frag.gql.mjs';

export const GET_ENGAGEMENTS = `
query GetEngagements(
  $workspaceId: ID!,
  $after: String,
  $first: Int,
  $before: String,
  $last: Int,
  $search: String,
  $sort: EngagementsSorting,
  $filters: [EngagementsFilter]
) {
  workspace(workspaceId: $workspaceId) {
    engagements(
      first: $first,
      last: $last,
      search: $search,
      after: $after,
      before: $before,
      sort: $sort,
      filters: $filters
    ) {
      nodes {
        ${typeAudienceLight}
        description
        emailIds
        ${typeEngagementStats}
        groupSize
        id
        isLive
        matchesCount
        name
        optInClosesAt
        optInFormsCount
        optInOpensAt
        product
        slug
        status
        timezoneIso
        ${typeEngagementRecurrence}
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      totalCount
    }
    id
  }
}
`;
