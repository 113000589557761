type SimpleTab = {
  id: string;
  label: string;
}

interface SimpleTabsProps {
  tabs: SimpleTab[];
  activeTab: string;
  onClick: (id: string) => void;
}

const SimpleTabs = ({
  tabs, onClick, activeTab,
}: SimpleTabsProps) => {
  const handleOnClick = (id: string) => () => onClick(id);

  const isActive = (id: string) => activeTab === id;

  return (
    <ul className="flex gap-0.5 mb-6">
      {tabs.map(({
        id, label,
      }) => (
        <li className="grow" key={id}>
          <button
            className={`border border-gray-300 border-solid py-3 px-6 w-[100%] ${ isActive(id) ? 'bg-orbiit-blue text-white' : 'bg-gray-100' }`}
            onClick={handleOnClick(id)}
            type="button"
          >
            {label}
          </button>
        </li>
      ))}
    </ul>
  );
};

SimpleTabs.displayName = 'SimpleTabs';

export { SimpleTabs };
export type { SimpleTabsProps, SimpleTab };
