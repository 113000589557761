import {
  HivebriteSegmentData, HivebriteSegmentDataItem, HivebriteSegmentType,
} from '…/app/w/workspace/audiences/audience/members/hivebrite/types.js';

// TODO: replace with actual implementation when the backend is ready
const getHivebriteGroup = (query?: string) : Promise<HivebriteSegmentData> => {
  const dataSet = Array(150).fill(0)
    .map((_, i) => ({
      id: 10000 + i*2,
      name: `LUUK - Group ${i}`,
    }));

  const results = query ? dataSet.filter(({ name }) => name.toLowerCase().includes(query.toLowerCase())) : dataSet;

  return Promise.resolve<HivebriteSegmentData>({
    query,
    results,
    type: 'group',
  });
};

// TODO: replace with actual implementation when the backend is ready
const getHivebriteEvents = (query?: string) : Promise<HivebriteSegmentData> => {
  const dataSet = Array(150).fill(0)
    .map((_, i) => ({
      id: 10000 + i*2,
      name: `LUUK - Event ${i}`,
    }));

  const results = query ? dataSet.filter(({ name }) => name.toLowerCase().includes(query.toLowerCase())) : dataSet;

  return Promise.resolve<HivebriteSegmentData>({
    query,
    results,
    type: 'event',
  });
};

// TODO: replace with actual implementation when the backend is ready
const getHivebriteClusters = (query?: string) : Promise<HivebriteSegmentData> => {
  const dataSet = Array(150).fill(0)
    .map((_, i) => ({
      id: 10000 + i*2,
      name: `LUUK - Cluster ${i}`,
    }));

  const results = query ? dataSet.filter(({ name }) => name.toLowerCase().includes(query.toLowerCase())) : dataSet;

  return Promise.resolve<HivebriteSegmentData>({
    query,
    results,
    type: 'cluster',
  });
};

const getHivebriteSegment = async(type: HivebriteSegmentType, query?: string): Promise<HivebriteSegmentData> => {
  console.log('getHivebriteSegment', type, query);

  switch (type) {
    case 'cluster':
      return getHivebriteClusters(query);
    case 'event':
      return getHivebriteEvents(query);
    case 'group':
      return getHivebriteGroup(query);
    default:
      return Promise.reject(new Error('Invalid segment type'));
  }
};

export { getHivebriteSegment };
export type { HivebriteSegmentData, HivebriteSegmentDataItem };
