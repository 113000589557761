import { SETUP_SECTIONS } from '../../constants.mts';

import EmailTips from './emails/email-tips.svg';
import FeedbackTips from './participant-forms/feedback-tips.svg';
import OptinTips from './participant-forms/optin-tips.svg';

import type { SetupAdvice } from './SetupAdvice.d.ts';


const emailAdvice: SetupAdvice = {
  Figure: EmailTips,
  article: {
    href: 'https://intercom.help/orbiit/en/?q=Announcement+Email',
    label: 'Read guide',
  },
  heading: 'Craft emails that convert',
  tips: [
    'Customize the message to your audience. Reflect their needs and challenges.',
    'Add your brand to stand out.',
    'Break contents down into short clear sections',
    'Write a clear subject and preview text to increase open rates 📈',
    'Adding emojis! Who doesnʼt like a fun emoji every now and then.',
  ],
};

export const setupPageConfig = {
  [SETUP_SECTIONS.CONVO_GUIDE]: {
    dataPath: 'conversationGuide',
    emailFieldName: 'json',
    title: 'Conversation Guide',
  },
  [SETUP_SECTIONS.FEEDBACK]: {
    advice: {
      Figure: FeedbackTips,
      article: {
        href: 'https://intercom.help/orbiit/en/articles/6453576-feedback-form-faqs',
        label: 'Learn more',

      },
      heading: 'Grow with Feedback',
      tips: [
        'Ratings, NPS and testimonials are all created from feedback form responses.',
        'The form is automatically shared with participants twice, after the conversation and 24 hours later.',
        'Use custom questions to get feedback that is specific to your goals and organisation.',
      ],
    },
    dataPath: 'feedbackQuestions',
    title: 'Feedback Form',
  },
  [SETUP_SECTIONS.INVITATION]: {
    advice: emailAdvice,
    dataPath: 'oneToOneEmails.invite', // ORB-334
    emailFieldName: 'bodyJson',
    title: 'Invite Email',
  },
  [SETUP_SECTIONS.REMINDER]: {
    advice: emailAdvice,
    dataPath: 'oneToOneEmails.chaser', // ORB-334
    emailFieldName: 'bodyJson',
    title: 'Reminder Email',
  },
  [SETUP_SECTIONS.SIGNUP]: {
    advice: {
      Figure: OptinTips,
      article: {
        href: 'https://intercom.help/orbiit/en/articles/6778433-optimizing-opt-ins-for-1-1-engagements',
        label: 'Read guide',

      },
      heading: 'Match like a Pro',
      tips: [
        'Relevant matching questions are key to a great experience.',
        'Keep it short & simple to increase opt-in and completion rates.',
        'Use your welcome message to focus participants on the benefits.',
      ],
    },
    dataPath: 'optInQuestions',
    hasWelcome: true,
    title: 'Opt-in Form',
  },
  [SETUP_SECTIONS.SCHEDULE]: {
    title: 'Engagement Schedule',
  },
  [SETUP_SECTIONS.TEMPLATE]: {
    title: 'Choose Template',
  },
} as const;
