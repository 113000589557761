import { gqlOperation } from '…/app/common/rpc/gqlOperation.mts';
import { omit } from 'lodash-es';

import { INITIALISE_ENGAGEMENT_TEMPLATE } from './initialiseEngagementTemplate.gql.mjs';
import { INITIAL_CONVERSATION_GUIDE } from './conversationGuide.mjs';


export function setEngagementTemplate({
  areasOfInterest,
  conversationGuide,
  description,
  engagementId,
  emails,
  title,
  workspaceId,
}) {
  return gqlOperation(INITIALISE_ENGAGEMENT_TEMPLATE, {
    areasOfInterest,
    chaserEmail: omit(emails.chaser, ['id', 'type', 'sendAt', 'isDisabled']),
    chaserEmailType: 'CHASER',
    conversationGuide: { json: conversationGuide.json || INITIAL_CONVERSATION_GUIDE },
    conversationGuideId: conversationGuide.id,
    description,
    engagementId,
    inviteEmail: omit(emails.invite, ['id', 'type', 'sendAt', 'isDisabled']),
    inviteEmailType: 'INVITE',
    title,
    workspaceId,
  })
    .then((data) => data)
    .catch((error) => {
      console.error(error);
      throw error;
    });
}
