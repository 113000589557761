interface ImportHivebriteMembers {
  type: string;
  id: string | number;
  audienceId: string | number;
}

// TODO: replace with actual implementation when the backend is ready
const importHivebriteMembers = ({
  type, id, audienceId,
}: ImportHivebriteMembers) => {
  console.log('importHivebriteMembers', type, id, audienceId);

  return Promise.resolve({
    audienceId,
    id,
    type,
  });
};


export { importHivebriteMembers };
