const freeResponseQuestionFields = `
  props {
    ledeIn
    title
  }
`;
const customQuestionFields = `
  isDataPrivate
  isOrdered
  isMatchingOpposites
  isMatchingDisabled
  question
`;
const multipleChoiceQuestionFields = `
  props {
    ledeIn
    options {
      id
      text
    }
    title
    variableConfig
  }
`;
const typeDependentQuestionFields = `
  ... on EngagementQuestionGenericSingleSelectStep {
    ${customQuestionFields}
    ${multipleChoiceQuestionFields}
  }
  ... on EngagementQuestionGenericMultiSelectStep {
    ${customQuestionFields}
    ${multipleChoiceQuestionFields}
  }
  ... on EngagementQuestionGenericTextStep {
    ${customQuestionFields}
    ${freeResponseQuestionFields}
  }
`;


export default `
fragment engagementFields on Engagement {
  id
  __typename
  audienceId
  audience {
    id
    name
  }
  engagementStats {
    nps
    numFeedbackForms
  }
  isEditMatchesEnabled
  isLive
  isInReview
  audienceId
  conversationDates
  conversationGuide {
    id
    isDisabled
    json
  }
  createdAt
  cancelledAt
  description
  groupSize
  matchIntroAt
  matchesCount
  matchRating { rating }
  name
  optInClosesAt
  optInFormsCount
  optInOpensAt
  product
  scheduledAt
  slug
  status
  timezoneIso
  title
  oneToOneEmails {
    chaser {
      bodyJson
      callToAction
      isDisabled
      preheader
      sendAt
      subject
    }
    invite {
      bodyJson
      callToAction
      isDisabled
      preheader
      sendAt
      subject
    }
  }
  feedbackQuestions {
    __typename
    id
    isDisabled
    lockedProps
    question
    stepName
    ... on EngagementQuestionGenericRatingStep {
      ${freeResponseQuestionFields}
    }
    ... on EngagementQuestionSpecificNPSStep {
      ${freeResponseQuestionFields}
    }
    ${typeDependentQuestionFields}
  }
  optInQuestions {
    __typename
    id
    isDisabled
    lockedProps
    stepName
    ... on EngagementQuestionGenericUserStep {
      ${freeResponseQuestionFields}
    }
    ... on EngagementQuestionSpecificProfessionalStep {
      ${freeResponseQuestionFields}
    }
    ... on EngagementQuestionSocialStep {
      ${freeResponseQuestionFields}
      props {
        variableConfig
      }
    }
    ... on EngagementQuestionSpecificPhoneStep {
      ${freeResponseQuestionFields}
    }
    ${typeDependentQuestionFields}
  }
  ... on EngagementRecurring {
    interval
    isRecurringEnabled
    repeat
    seriesEndDate
    seriesId
  }
  durationMinutes
  emailsSentFromAddress
  batchSize
  disableCompanyBlock
  isAutoExecute
  sendNoshowEmail
  sendNotMatchedEmail
}
`;
