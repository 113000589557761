import { Fragment } from 'react';

import type { WidgetConfigBase } from '…/app/common/widget.mts';

import { DeleteAudienceForm } from './DeleteAudienceForm.tsx';
import { UpsertAudienceForm } from './UpsertAudienceForm.tsx';

import { BatchUpdateMembersForm } from './members/BatchUpdateMembersForm.tsx';
import { DeleteMembersForm } from './members/DeleteMembersForm.tsx';
import { RemoveMembersFromAudienceForm } from './members/RemoveMembersFromAudienceForm.tsx';
import { MemberHistory } from './members/MemberHistory.tsx';
import { UpsertMemberForm } from './members/UpsertMemberForm.tsx';
import { FiltersForm } from './FiltersForm.tsx';
import { HivebriteImportFormWrapper } from './members/hivebrite/HivebriteImportFormWrapper.tsx';


export const widgets = {
  '': Fragment,
  add: UpsertMemberForm,
  'audience.delete': DeleteAudienceForm,
  'audience.rename': UpsertAudienceForm,
  batch: BatchUpdateMembersForm,
  'batch.audience': BatchUpdateMembersForm,
  delete: DeleteMembersForm,
  edit: UpsertMemberForm,
  filters: FiltersForm,
  history: MemberHistory,
  'hivebrite.import': HivebriteImportFormWrapper,
  remove: RemoveMembersFromAudienceForm,
};

export interface WidgetConfig extends WidgetConfigBase {
  name: keyof typeof widgets,
}
