export const rpcOrigin = ((base) => {
  const url = new URL(base);

  const cloudflarePagesUat = /^https:\/\/[a-zA-Z0-9]+\.orbiit-app-uat\.pages\.dev(\/\w*)?$/;
  const cloudflarePagesProd = /^https:\/\/[a-zA-Z0-9]+\.orbiit-app-prod\.pages\.dev(\/\w*)?$/;
  if (cloudflarePagesUat.test(location.origin)) {
    url.hostname = 'api.uat.orbiit.ai';
  } else if (cloudflarePagesProd.test(location.origin)) {
    url.hostname = 'api.orbiit.ai';
  } else {
    url.hostname = url.hostname.replace('app.', 'api.');
  }

  return url.origin;
})(location.origin); // eslint-disable-line no-restricted-globals
