import { formatDate } from '…/app/common/dateUtils.mts';

import { Chip } from '…/app/w/workspace/common/Chip/Chip.tsx';

import classes from './EmailStatRow.module.css';

export function EmailStatRow({ obj }: { obj: any }) {
  return (
    <div className={classes.EmailEventRow}>
      <div><Chip>{obj.event}</Chip></div>

      <div>{obj.email}</div>

      <div>{formatDate(obj.lastTimestamp, null, true)}</div>
    </div>
  );
}
EmailStatRow.displayName = 'EmailStatRow';
