import { HivebriteSource } from '…/app/w/workspace/audiences/audience/members/hivebrite/types.js';

const getSourceInformation = ({ type, id }: { type: string, id: string | number }): Promise<HivebriteSource> => {
  console.log('getSourceInformation', type, id);

  if (!type) {
    return Promise.reject(new Error('No segment selected'));
  }

  return new Promise<HivebriteSource>((resolve) => {
    setTimeout(() => {
      resolve({
        id,
        numberOfMembers: 137,
        type,
      });
    }, 1500);
  });
};

export {getSourceInformation};
