import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'sonner';
import clsx from 'clsx';
import Button from 'form5/react/Button';
import { pick } from 'lodash-es';

import { handlePageErrors } from '…/app/common/errors/handlePageErrors.mts';
import { useAdminRole } from '…/app/common/permissions/useRoles.mjs';
import { FLAGS } from '…/app/common/permissions/Features/FLAGS.mts';
import { useFeatureFlag } from '…/app/common/permissions/Features/useFeatureFlag.mts';
import { SubmitButton } from '…/app/common/SubmitButton/SubmitButton.jsx';

import { getWorkspaceWhitelabelingData } from './getWorkspaceWhitelabelingData.op.mts';
import { verifyWhitelabelingDomain } from './verifyWhitelabelingDomain.op.mts';

import { DNSValidationCard } from './DNSValidationCard.tsx';
import { AddDomainDialog } from './AddDomainDialog.tsx';

import panelClasses from '../SettingsPanel.module.css';
import classes from './WorkspaceWhitelabelingPage.module.css';

export function WorkspaceWhitelabelingPage() {
  const { workspaceId } = useParams();

  const isWhitelabelingEnabled = useFeatureFlag([FLAGS.workspaceWhitelabeling]);
  const isAdmin = useAdminRole();

  const [addDomainDialogOpen, setAddDomainDialogOpen] = useState(false);
  const [whitelabeling, setWhitelabeling] = useState(null);
  const [isVerifying, setIsVerifying] = useState(false);
  const [refresh, setRefresh] = useState(true);

  useEffect(() => {
    if (!refresh) return;

    getWorkspaceWhitelabelingData({ workspaceId })
      .then((data) => {
        setWhitelabeling(data.workspace.whitelabeling);
      })
      .catch(handlePageErrors)
      .finally(() => setRefresh(false));
  }, [refresh]);

  const onVerifyWhitelabelingDomain = (event) => {
    event.preventDefault();
    setIsVerifying(true);

    verifyWhitelabelingDomain({ workspaceId })
      .then((data) => {
        const { sendgrid } = data.workspace.whitelabeling;

        if (sendgrid && sendgrid.mailCname?.valid && sendgrid.dkim1?.valid && sendgrid.dkim2?.valid) {
          toast.success('Whitelabeling domain verified successfully');
        } else {
          toast.warning('Whitelabeling domain verification failed');
        }
      })
      .catch(handlePageErrors)
      .finally(() => {
        setIsVerifying(false);
        setRefresh(true);
      });
  };

  return (
    <>
      <div className={panelClasses.SettingsPanel}>
        <h1>Workspace Whitelabeling</h1>

        <section className={clsx('Card', panelClasses.Section)}>
          <div className={clsx(classes.Content, 'flex flex-col gap-4')}>
            <div className="SplitHeader">
              <h2>Email Domain Whitelabeling</h2>

              {isWhitelabelingEnabled && isAdmin && whitelabeling && (
                <SubmitButton
                  appearance={Button.APPEARANCES.BASIC}
                  className="Button"
                  disabled={isVerifying}
                  isSubmitting={isVerifying}
                  onClick={onVerifyWhitelabelingDomain}
                >
                  Verify
                </SubmitButton>
              )}
            </div>

            {!isWhitelabelingEnabled && (
              <p>You currently don&apos;t have access to use domain whitelabeling.</p>
            )}

            {isWhitelabelingEnabled && !isAdmin && (
              <p>Only an Admin can manage the whitelabeling settings for this workspace.</p>
            )}

            {isWhitelabelingEnabled && isAdmin && !whitelabeling && (
              <>
                <p>To use domain whitelabeling you will first need to add the domain you want to whitelabel.</p>

                <Button
                  className="Button Button--affirming"
                  onClick={() => setAddDomainDialogOpen(true)}
                >
                  Add Domain
                </Button>
              </>
            )}

            {isWhitelabelingEnabled && isAdmin && whitelabeling && (
              <>
                <p>
                  You are using the domain <strong>{whitelabeling.domain}</strong> for your workspace.

                  The emails will be sent from <strong>{whitelabeling.fromEmailAddress}</strong>.
                </p>

                <p>
                  Make sure to correctly configure the DNS records below:
                </p>

                <div className={classes.SectionSplit}>
                  <div className="flex flex-col gap-4">
                    {Object.entries(pick(whitelabeling.sendgrid, ['mailCname', 'dkim1', 'dkim2'])).map(([key, record]) => (
                      <div key={key}>
                        <DNSValidationCard
                          record={record}
                          recordKey={key}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              </>
            )}
          </div>
        </section>
      </div>

      <AddDomainDialog
        open={addDomainDialogOpen}
        setOpen={setAddDomainDialogOpen}
        setRefresh={setRefresh}
      />
    </>
  );
}
WorkspaceWhitelabelingPage.displayName = 'WorkspaceWhitelabelingPage';

const handle = {
  title: 'Workspace Integrations',
};

export {
  WorkspaceWhitelabelingPage as Component,
  handle,
};

