import {Radio, RadioProps} from '…/app/common/Radios/Radio.js';

interface RadiosProps {
  name: string;
  options: Pick<RadioProps, 'description' | 'label' | 'value'>[];
  value: string;
  onChange: (value: string) => void;
}

const Radios = ({
  name, options = [], value, onChange,
}: RadiosProps) => {
  const handleOnChange = (newValue: string) => () => {
    onChange?.(newValue);
  };

  return (
    <ul className="flex flex-col gap-3">
      {options.map(({
        value: optionValue, label, description,
      }) => (
        <li key={optionValue}>
          <Radio
            checked={optionValue === value}
            description={description}

            label={label}
            name={name}
            onChange={handleOnChange(optionValue)}
            value={optionValue}
          />
        </li>
      ))}
    </ul>
  );
};

Radios.displayName = 'Radios';

export { Radios };
