interface RadioProps {
  label: string;
  value: string;
  name: string;
  checked: boolean;
  onChange: () => void;
  description?: string;
}

const Radio = ({
  label, value, name, checked, onChange, description,
}: RadioProps) => (
  <div className="flex items-start gap-2">
    <input
      checked={checked}
      id={value}
      name={name}
      onChange={onChange}
      type="radio"
      value={value}
    />

    <label className="block cursor-pointer font-normal" htmlFor={value}>
      <span className="inline-block mb-0.5">{label}</span>

      {description && <span className="block text-gray-500">{description}</span>}
    </label>
  </div>
);

Radio.displayName = 'Radio';

export { Radio };
export type { RadioProps };
